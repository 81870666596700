import { getAudioContext } from '../../services/audio/audio-context';

type MESNGainTuple = readonly [MediaElementAudioSourceNode, GainNode];

export class MESNFactory {
  // NOTE: do not delete the pair from the map because an audio node can only
  // be converted via createMediaElementSource() once and will throw if it
  // happens again. Therefore, this must be a global map.
  private static readonly mesnMap = new WeakMap<
    HTMLMediaElement,
    MESNGainTuple
  >();

  constructor(
    private destination: AudioNode,
    private audioCtx = getAudioContext()
  ) {}

  wire(video: HTMLMediaElement): MESNGainTuple {
    let pair = MESNFactory.mesnMap.get(video);
    if (!pair) {
      const mesn = this.audioCtx.createMediaElementSource(video);
      const gain = this.audioCtx.createGain();
      pair = [mesn, gain];
      MESNFactory.mesnMap.set(video, pair);
    }

    // Always reconnect: we are potentially sharing audio nodes on mobile
    // safari, they might still be connected to something else.
    const [mesn, gain] = pair;

    // Disconnect from any previous connections, these are shared resources.
    mesn.disconnect();
    gain.disconnect();

    // Reset any state changes: something else might have changed the gain.
    gain.gain.cancelScheduledValues(0);
    gain.gain.value = 1;

    // Connect to the destination
    mesn.connect(gain);
    gain.connect(this.destination);

    return pair;
  }

  /**
   * Only safe to use in a non-audio-pooled environment, such as the legacy
   * venue.
   */
  unwire(video: HTMLMediaElement): void {
    const pair = MESNFactory.mesnMap.get(video);
    if (!pair) return;
    const [mesn, gain] = pair;
    mesn.disconnect();
    gain.disconnect();
    MESNFactory.mesnMap.delete(video);
  }
}
