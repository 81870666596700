import { assertExhaustive } from './common';

export enum ScalePolicy {
  ScaleSource,
}

type Rect = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export function scaleSource(
  sw: number,
  sh: number,
  dw: number,
  dh: number
): Rect {
  const hRatio = dw / sw;
  const vRatio = dh / sh;
  const ratio = Math.max(hRatio, vRatio);
  const x = (sw - dw / ratio) / 2;
  const y = (sh - dh / ratio) / 2;
  return { x, y, w: sw - x * 2, h: sh - y * 2 };
}

function getWidth(source: HTMLVideoElement | HTMLImageElement) {
  return 'videoWidth' in source ? source.videoWidth : source.width;
}

function getHeight(source: HTMLVideoElement | HTMLImageElement) {
  return 'videoHeight' in source ? source.videoHeight : source.height;
}

export class Canvas2DHelper {
  private ctx: CanvasRenderingContext2D;
  constructor(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx;
  }

  drawImage(
    source: HTMLVideoElement | HTMLImageElement,
    dw: number,
    dh: number,
    scalePolicy?: ScalePolicy
  ): void {
    const sw = getWidth(source);
    const sh = getHeight(source);

    if (scalePolicy === undefined) {
      this.ctx.drawImage(source, 0, 0, sw, sh, 0, 0, dw, dh);
    } else if (scalePolicy === ScalePolicy.ScaleSource) {
      const { x, y, w, h } = scaleSource(sw, sh, dw, dh);
      this.ctx.drawImage(source, x, y, w, h, 0, 0, dw, dh);
    } else {
      assertExhaustive(scalePolicy);
    }
  }

  fillRoundRect(
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
  ): void {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    this.ctx.beginPath();
    this.ctx.moveTo(x + radius, y);
    this.ctx.arcTo(x + width, y, x + width, y + height, radius);
    this.ctx.arcTo(x + width, y + height, x, y + height, radius);
    this.ctx.arcTo(x, y + height, x, y, radius);
    this.ctx.arcTo(x, y, x + width, y, radius);
    this.ctx.closePath();
    this.ctx.fill();
  }
}

// Drawing to a canvas that is not attached to the DOM in Safari leaks until the
// canvas itself is Garbage Collected. Example sandbox demonstrating the issue:
// https://codesandbox.io/s/safari-webkit-detached-canvas-memory-leak-8p7hz. The
// workaround is to attach the canvas to the DOM, and make it invisible.
export class HiddenCanvas {
  cvs = document.createElement('canvas');
  constructor(
    key: string,
    options?: {
      visible?: boolean;
      className?: string;
    }
  ) {
    const opts = Object.assign({}, { visible: false }, options);
    this.cvs.setAttribute('data-key', key);
    if (!opts.visible) {
      this.cvs.style.display = 'none';
    }
    if (opts.className) {
      this.cvs.className = opts.className;
    }
  }
  attach(el?: HTMLElement): void {
    if (!el) document.body.appendChild(this.cvs);
    else el.appendChild(this.cvs);
  }
  detach(): void {
    this.cvs.parentNode?.removeChild(this.cvs);
  }
}
