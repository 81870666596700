import { ref } from 'valtio';

export interface AbortSignalableRunner {
  start(): Promise<unknown>;
  finished: null | Promise<unknown>;
  destroy(reason?: unknown): Promise<unknown>;
}

export class AbortableRunner<TReturn, TDestroyReason>
  implements AbortSignalableRunner
{
  static CreatedVRefed<TReturn, TDestroyReason>(
    ...params: ConstructorParameters<
      typeof AbortableRunner<TReturn, TDestroyReason>
    >
  ): AbortableRunner<TReturn, TDestroyReason> {
    return ref(new AbortableRunner<TReturn, TDestroyReason>(...params));
  }

  private finishedPromise: null | Promise<TReturn> = null;

  constructor(
    private Runnable: (
      ctrl: AbortControllerWithReasoned<TDestroyReason>
    ) => Promise<TReturn>,
    private aborter = makeReasonedAbortController<TDestroyReason>()
  ) {}

  get signal() {
    return this.aborter.signal;
  }

  async start(): Promise<TReturn> {
    if (this.finishedPromise) return this.finishedPromise;
    this.finishedPromise = this.Runnable(this.aborter);
    return await this.finishedPromise;
  }

  get finished(): null | Promise<TReturn> {
    return this.finishedPromise;
  }

  get aborted(): boolean {
    return this.aborter.signal.aborted;
  }

  async destroy(reason?: TDestroyReason): Promise<unknown> {
    if (this.aborter.signal.aborted) return await this.finishedPromise;
    // Abort using microtask queue so that if the runner is actually mostly
    // synchronous, it has a chance to return.
    Promise.resolve().then(() => this.aborter.abort(reason));
    return await this.finishedPromise;
  }
}

type AbortControllerWithReasoned<Reason> = AbortController & {
  abort(reason: Reason): void;
  signal: AbortSignalWithReason<Reason>;
};

interface AbortSignalWithReason<Reason> extends AbortSignal {
  reason: Reason;
}

// helper to avoid needing to type hint abortcontroller multiple times.
function makeReasonedAbortController<TReason>() {
  return new AbortController() as AbortControllerWithReasoned<TReason>;
}

/**
 * A generator-powered runner. The generator can be sync or async. Any `yield`
 * will result in an opportunity for the runner to halt due to aborting
 * (`.destroy(reason)`), even if the generator is currently `await`-ing a
 * long-running task (it's a race).
 *
 * When aborted, the return value of the runner will be the abort `reason`. If
 * not aborted, the generator's return value will be used instead.
 *
 * Anything that is `yield`ed will be passed back into the generator as the next
 * value. Typescript is limited on how it can type `yield`/`next` types (each is
 * actually a union of all possible yield or next types...), so it's safest to
 * assert that the result of a yield statement is what you expect.
 *
 * If either an uncaught rejection or exception occurs, the runner's `finished`
 * will reject as well. This allows catching any internal errors and cleaning
 * up.
 *
 * NOTE: the `aborter` is just an AbortController. It just happens to have
 * strongly-typed `reason` property in types-only.
 */
export function YieldableAbortableRunner<
  TYield,
  TReturn,
  TNext,
  TDestroyReasons = never,
  Task extends
    | (Generator<TYield, TReturn, TNext> | null | undefined)
    | (AsyncGenerator<TYield, TReturn, TNext> | null | undefined) =
    | (Generator<TYield, TReturn, TNext> | null | undefined)
    | (AsyncGenerator<TYield, TReturn, TNext> | null | undefined)
>(task: () => Task, aborter = makeReasonedAbortController<TDestroyReasons>()) {
  return new AbortableRunner<
    TReturn | undefined | TDestroyReasons,
    TDestroyReasons
  >(async (ctrl) => {
    const c = task();
    let ret: TDestroyReasons | TReturn | undefined;
    let nextValue: unknown;

    const abortSymbol = Symbol('abort');
    const aborted = new Promise<typeof abortSymbol>((resolve) =>
      ctrl.signal.addEventListener('abort', () => resolve(abortSymbol))
    );

    while (true) {
      if (!c) break;
      // Note: the TNext ("result value of the yield") is a union of all
      // `yield` types, so it's not very useful if specified. But at least pass
      // the value through (`.next(value)`), since there is no other external
      // communication or messsage-passing into the generator.
      const next = await Promise.race([c.next(nextValue as TNext), aborted]);

      if (next === abortSymbol) break;

      // Ensure the return type is used to resolve the final runner promise.
      if (next.done) {
        ret = next.value;
        break;
      }

      // Grab the last value so it can be fed back into the generator.
      nextValue = await Promise.race([next.value, aborted]);

      if (ctrl.signal.aborted) {
        break;
      }
    }

    return ret ?? (ctrl.signal.reason as TDestroyReasons);
  }, aborter);
}
