import axios from 'axios';

import logger from '../../logger/logger';
import { getAudioContext } from './audio-context';

const log = logger.scoped('downloader');

export async function decodeAudioData(
  ctx: AudioContext,
  ab: ArrayBuffer
): Promise<AudioBuffer> {
  return new Promise<AudioBuffer>((resolve, reject) => {
    // Using the callback form of decodeAudioData due to older Safari, which
    // often does not emit an actual Error object, and does not track rejected
    // promises well, either.
    ctx.decodeAudioData(
      ab,
      (buffer) => {
        resolve(buffer);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// audioContext.decodeAudioData is notoriously flaky depending on the platform.
// Multiple concurrent requests to it will often throw inscrutable errors about
// decoding failed. The solution is to limit concurrent decode requests. NOTE:
// http requests are not limited, only audio decode calls.
export class DecodeQueue {
  private cache: Map<string, Promise<AudioBuffer>> = new Map();

  private enqueuedDecodes: Map<string, () => Promise<void>> = new Map();
  private activeDecodes = new Set<string>(); // url

  constructor(
    public decodeConcurrency = 5,
    private fetcher: (url: string) => Promise<{ data: ArrayBuffer }> = (
      url: string
    ) => axios.get<ArrayBuffer>(url, { responseType: 'arraybuffer' }),
    private getAudioCtx = getAudioContext
  ) {}

  async get(url: string): Promise<AudioBuffer> {
    return this.enqueue(url);
  }

  async enqueue(url: string): Promise<AudioBuffer> {
    const enqueued = this.cache.get(url);
    if (enqueued) return enqueued;

    log.debug('enqueuing', { url: url });

    // Representation of the task: download, then decode.
    const p = new Promise<AudioBuffer>(async (resolve, reject) => {
      let resp: { data: ArrayBuffer } | null = null;

      try {
        log.debug('fetch start', { url: url });
        resp = await this.fetcher(url);
        log.debug('fetch finish', { url: url });
      } catch (err) {
        reject(err);
      }

      if (!resp) return;

      // This is awkward, but the enqueued executor needs access to the
      // `resolve` in order to propagate changes back. Alternative is a Deferred
      // pattern to reduce nesting.
      const executor = async () => {
        if (!resp) return reject(new Error('NoDataToDecode'));

        log.debug('decode start', { url: url });
        try {
          const ctx = this.getAudioCtx();
          const buffer = await decodeAudioData(ctx, resp.data);
          log.debug('decode finish', { url: url });
          resolve(buffer);
        } catch (err) {
          reject(err);
        }
      };

      this.enqueuedDecodes.set(url, executor);
      this.kick();
    });

    // If the entire task fails, handle clearing/resetting state.
    p.catch((err) => {
      log.error(`enqueue failed`, err, { url });
      this.enqueuedDecodes.delete(url);
      this.cache.delete(url);
    });

    this.cache.set(url, p);
    this.kick();
    return p;
  }

  private async kick() {
    if (this.activeDecodes.size >= this.decodeConcurrency) return;
    const next = this.enqueuedDecodes.entries().next();
    if (next.done) return;
    const [url, executor] = next.value;
    this.enqueuedDecodes.delete(url);
    // If the executor throws, it signifies an unrecoverable/undefined state.
    this.activeDecodes.add(url);
    await executor();
    this.activeDecodes.delete(url);
    this.kick();
  }
}

export const decoder = new DecodeQueue();

export function preloadAssets(
  src: string | { [name: string]: string } | string[]
): void {
  const urls = typeof src === 'string' ? [src] : Object.values(src);
  Promise.all(urls.filter(Boolean).map((url) => decoder.enqueue(url).catch()));
}
