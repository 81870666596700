export type RequestVideoFrameCallbackCallback = (
  now: number,
  metadata: {
    expectedDisplayTime: DOMHighResTimeStamp;
    width: number; // unsigned long
    height: number; // unsigned long
    mediaTime: number; // double, seconds
    presentationTime: DOMHighResTimeStamp;
    presentedFrames: number; // unsigned long
    processingDuration: number; // double, seconds

    // webrtc sources only:
    captureTime?: DOMHighResTimeStamp;
    receiveTime?: DOMHighResTimeStamp;
    rtpTimestamp?: number; // unsigned long
  }
) => void;

export interface RequestVideoFrameCallbackable {
  requestVideoFrameCallback(
    callback: RequestVideoFrameCallbackCallback
  ): number;
  cancelVideoFrameCallback(handle: number): void;
}

export function requestVideoFrameCallbackAvailable(
  video: HTMLVideoElement | null
): video is HTMLVideoElement & RequestVideoFrameCallbackable {
  return video !== null && 'requestVideoFrameCallback' in video;
}
