import { type IEventedVideoMixer, type TrackId } from './types';

export function createTrackEndAwaiter(
  videoMixer: IEventedVideoMixer,
  trackId: TrackId
) {
  return new Promise<void>((resolve) => {
    videoMixer.on('track-end', function handler(startedTrackId, _playheadMs) {
      if (startedTrackId !== trackId) return;
      videoMixer.off('track-end', handler);
      resolve();
    });
  });
}

export function createTrackStartAwaiter(
  videoMixer: IEventedVideoMixer,
  trackId: TrackId
) {
  return new Promise<void>((resolve) => {
    videoMixer.on('track-start', function handler(startedTrackId, _playheadMs) {
      if (startedTrackId !== trackId) return;
      videoMixer.off('track-start', handler);
      resolve();
    });
  });
}

export function createTrackFirstFrameAwaiter(
  videoMixer: IEventedVideoMixer,
  trackId: TrackId
) {
  return new Promise<void>((resolve) => {
    videoMixer.on('track-first-frame-render', function handler(tracks) {
      if (!tracks.has(trackId)) return;
      videoMixer.off('track-first-frame-render', handler);
      resolve();
    });
  });
}

export function createTrackRemoveAwaiter(
  videoMixer: IEventedVideoMixer,
  trackId: TrackId
) {
  return new Promise<void>((resolve) => {
    videoMixer.on(
      'track-remove',
      function handler(startedTrackId, _playheadMs) {
        if (startedTrackId !== trackId) return;
        videoMixer.off('track-remove', handler);
        resolve();
      }
    );
  });
}
