import { type IntrinsicScaleCalculator } from 'intrinsic-scale';

import { type BoundingBox, type RelativeRect } from '@lp-lib/game';

export function calcCanvasDrawable(
  box: BoundingBox,
  fitOp: IntrinsicScaleCalculator,
  field: { width: number; height: number },
  source: { width: number; height: number },
  maskRect: RelativeRect
) {
  // In order to avoid affine/non-linear transformations and a double pixel
  // copy, the crop rect pct will be relative to the _video_ source, not the
  // bounding box. If it ends up relative to the bounding box, two pixel
  // operations must happen: 1) copy the object-fitted source into the
  // bounding box to perform scaling, translation, 2) copy the cropped box
  // onto the final output canvas. Performing this without a two-draw
  // operation is pretty difficult mathematically to transform between the
  // two coordinate spaces (source space, "field"/output space).

  // 1) compute field-space bounding box
  // 2) compute field-space destination draw box using object fit.
  // 3) compute source-space crop box source
  // 4) compute field-space destination crop box from field-space destination draw box
  // 5) draw pixels from source to destination

  // Field Space: Convert pct bounding box into pixels using the latest field dimensions.
  const boundingBoxPx: BoundingBox = {
    x: box.x * field.width,
    y: box.y * field.height,
    width: box.width * field.width,
    height: box.height * field.height,
  };

  // Relative Space: Compute the target draw coordinates to fit the source image accordingly
  const drawFitBox = fitOp(
    boundingBoxPx.width,
    boundingBoxPx.height,
    source.width,
    source.height
  );

  // Convert to Field Space
  drawFitBox.x += boundingBoxPx.x;
  drawFitBox.y += boundingBoxPx.y;

  const maskRectTop = maskRect.top;
  const maskRectRight = maskRect.right;
  const maskRectBottom = maskRect.bottom;
  const maskRectLeft = maskRect.left;

  // Source Space: crop box
  // Floor the X,Y to avoid subpixel rendering (slow), but use round(width +
  // remainder) to ensure a pixel is not lost or accidentally added (and the
  // width/height is still an integer).
  const rawX = maskRectLeft * source.width;
  const rawY = maskRectTop * source.height;
  const sx = Math.floor(rawX);
  const sy = Math.floor(rawY);
  const remainderX = rawX - sx;
  const remainderY = rawY - sy;
  const sw = Math.round(
    (1 - (maskRectLeft + maskRectRight)) * source.width + remainderX
  );
  const sh = Math.round(
    (1 - (maskRectBottom + maskRectTop)) * source.height + remainderY
  );

  // Field Space: destination crop box
  const ratioX = drawFitBox.width / source.width;
  const ratioY = drawFitBox.height / source.height;
  const rawDestX = drawFitBox.x + sx * ratioX;
  const rawDestY = drawFitBox.y + sy * ratioY;
  const destX = Math.floor(rawDestX);
  const destY = Math.floor(rawDestY);
  const remainderDestX = rawDestX - destX;
  const remainderDestY = rawDestY - destY;
  const destW = Math.round(sw * ratioX + remainderDestX);
  const destH = Math.round(sh * ratioY + remainderDestY);

  return { sx, sy, sw, sh, destX, destY, destW, destH };
}
