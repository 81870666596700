export type VideoMixerMarkerConfig<T = unknown> = {
  timelineTimeStartMs: number;
  extra?: T;
};

export class VideoMixerMarker {
  constructor(
    public readonly id: string,
    public readonly config: VideoMixerMarkerConfig
  ) {}
}
