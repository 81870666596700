import { type Logger } from '@lp-lib/logger-base';

import { type IVideoMixerTrack } from '../IVideoMixerTrack';
import { type VideoMixerEmitter } from '../types';
import { fixed4 } from '../utils';

export function trackStartTickHandler(
  prevTime: number,
  nextTime: number,
  track: IVideoMixerTrack,
  playing: boolean,
  log: Logger,
  emitter: VideoMixerEmitter
): void {
  // Time for track to play?

  const [startMs] = track.computeActiveTimelineTimeMs();

  if (playing && startMs >= prevTime && startMs < nextTime) {
    if (!track.playing) {
      track.play();
      const drift = startMs - prevTime;
      log.info(`track-start ${track.id}`, {
        playhead: fixed4(prevTime),
        timelineTimeStartMs: fixed4(startMs),
        drift: fixed4(drift),
      });
      emitter.emit('track-start', track.id, prevTime, drift);
      emitter.emit('track-playstate', track.id, track.playing, prevTime);
    }
  }
}
