// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

// Modifications:
// Remove async signatures.
// Fix assignment. Remove `destroyed` flag in favor of nullable canvas.
// Remove separate width/height in favor of canvas.width/height directly.

import type VideoFrameBuffer from './VideoFrameBuffer';

/**
 * [[CanvasVideoFrameBuffer]] implements [[VideoFrameBuffer]]. It internally holds an `HTMLCanvasElement`.
 */
export default class CanvasVideoFrameBuffer implements VideoFrameBuffer {
  framerate = 0;

  constructor(private canvas: HTMLCanvasElement | null) {}

  get width(): number {
    if (!this.canvas) {
      throw new Error('canvas buffer is destroyed');
    }
    return this.canvas.width;
  }

  set width(w: number) {
    if (!this.canvas) {
      throw new Error('canvas buffer is destroyed');
    }
    this.canvas.width = w;
  }

  get height(): number {
    if (!this.canvas) {
      throw new Error('canvas buffer is destroyed');
    }
    return this.canvas.height;
  }

  set height(w: number) {
    if (!this.canvas) {
      throw new Error('canvas buffer is destroyed');
    }
    this.canvas.height = w;
  }

  destroy(): void {
    this.canvas = null;
  }

  asCanvasImageSource(): CanvasImageSource {
    if (!this.canvas) {
      throw new Error('canvas buffer is destroyed');
    }
    return this.canvas;
  }

  asCanvasElement(): HTMLCanvasElement {
    if (!this.canvas) {
      throw new Error('canvas buffer is destroyed');
    }
    return this.canvas;
  }
}
