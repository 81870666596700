import logger from '../../logger/logger';
import { playWithCatch } from '../../utils/playWithCatch';
import { CanvasImageSourceFrameBuffer } from '../VideoFrameProcessing/CanvasImageSourceFrameBuffer';
import type VideoFrameBuffer from '../VideoFrameProcessing/vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import { type IVideoMixerTrack } from './IVideoMixerTrack';
import {
  type InjectedProcessors,
  ProcessorRegistry,
} from './ProcessorRegistry';
import {
  type AudioEventDesc,
  type TrackId,
  type TrackInitConfig,
} from './types';

const log = logger.scoped('video-mixer-track');

export class VideoMixerTrack implements IVideoMixerTrack {
  readonly vfbuffer: VideoFrameBuffer;
  private _playing = false;
  private processors: ProcessorRegistry;
  private destroyer = new AbortController();

  renderCount = 0;

  constructor(
    public readonly id: TrackId,
    public readonly internalMedia: HTMLMediaElement,
    public readonly config: TrackInitConfig,
    injectedProcessors?: Partial<InjectedProcessors>
  ) {
    this.vfbuffer = new CanvasImageSourceFrameBuffer(internalMedia);

    if (config.loop) {
      internalMedia.loop = true;
    } else {
      internalMedia.loop = false;
    }

    this.processors = new ProcessorRegistry(injectedProcessors);

    const opts = {
      signal: this.destroyer.signal,
    };

    internalMedia.addEventListener(
      'play',
      (ev) => log.debug(`trackId ${this.id}: play`, { event: ev }),
      opts
    );
    internalMedia.addEventListener(
      'error',
      (ev) =>
        log.error(`trackId ${this.id}: error`, internalMedia.error, {
          event: ev,
        }),
      opts
    );
    internalMedia.addEventListener(
      'stalled',
      (ev) => log.info(`trackId ${this.id}: stalled`, { event: ev }),
      opts
    );
    internalMedia.addEventListener(
      'suspended',
      (ev) => log.info(`trackId ${this.id}: suspended`, { event: ev }),
      opts
    );
  }

  getAudioSource(): HTMLVideoElement {
    return this.internalMedia as HTMLVideoElement;
  }

  sourceDescription(includeKind = true): string {
    const src = this.internalMedia.srcObject
      ? this.internalMedia.srcObject.constructor.name
      : this.internalMedia.src;
    return `${includeKind ? 'VideoTrack' : ''}(${this.id}, ${src})`;
  }

  acceptPatch(patch: Partial<TrackInitConfig>): boolean {
    const { audioEffects, visualEffects, ...primitives } = patch;
    (this.config as TrackInitConfig) = {
      ...this.config,
      ...primitives,
      audioEffects: {
        ...this.config.audioEffects,
        ...audioEffects,
      },
      visualEffects: {
        ...this.config.visualEffects,
        ...visualEffects,
      },
    };

    return true;
  }

  get playing(): boolean {
    return this._playing;
  }

  play(): void {
    // TODO: add event listeners to know when playing / paused?
    this._playing = true;
    if (this.internalMedia.paused) {
      playWithCatch(this.internalMedia, log);
      log.debug(`trackId ${this.id}: ${this.internalMedia.tagName}.play()`);
    }
  }

  pause(): void {
    this._playing = false;
    if (!this.internalMedia.paused) {
      this.internalMedia.pause();
      log.debug(`trackId ${this.id}: ${this.internalMedia.tagName}.pause()`);
    }
  }

  seekToMs(localTimeMs: number): void {
    this.internalMedia.currentTime = Math.max(
      Math.min(localTimeMs / 1000, this.internalMedia.duration),
      0
    );
  }

  async destroy(): Promise<HTMLMediaElement> {
    await this.processors.destroy();
    this.destroyer.abort();

    // The track could have been removed while still playing, especially if its
    // `computeActiveTimelineTimeMs` is infinite or still active (such as when
    // an entire Mixer instance is destroyed).
    if (this.playing) this.pause();

    // Reset stateful values that might persist in a pooling scenario.
    this.internalMedia.volume = 1;
    this.internalMedia.loop = false;

    return this.internalMedia;
  }

  get videoDurationMs(): number {
    if (isFinite(this.config.durationMs))
      return this.internalMedia.duration * 1000;
    return Infinity;
  }

  computeActiveTimelineTimeMs(): readonly [number, number] {
    // if current timeline-relative time (playhead) is within these bounds, then
    // a pause or resume would affect this track
    const start = this.config.timelineTimeStartMs;

    // timelineTimeEndMs takes priority over looping and streaming, if it is
    // finite.
    const scheduledDuration = this.config.timelineTimeEndMs - start;

    // If the track is looping, then the track is _always_ active beyond the
    // start.

    const end =
      this.config.timelineTimeStartMs +
      (isFinite(this.config.timelineTimeEndMs)
        ? scheduledDuration
        : this.config.loop
        ? Infinity
        : this.config.durationMs);
    return [start, end] as const;
  }

  computeDesync(
    timelineTimeMs: number
  ): { expectedLocalTimeMs: number; actualLocalTimeMs: number } | null {
    // An infinite track (a stream) cannot be desynced.
    if (!isFinite(this.config.durationMs)) return null;

    // attempt to determine the delta between where the video element is and
    // where the mixer expects it to be. The track is not considered desynced if
    // the video is shorter than the track duration.
    const expectedLocalTimeMs =
      timelineTimeMs - this.config.timelineTimeStartMs;
    const actualLocalTimeMs = this.internalMedia.currentTime * 1000;
    const EPSILON_MS = 500;

    // If the track is infinite (looping) then the track duration is a multiple
    // of actual duration and must be mapped to the actual video duration.
    const expectedLocalMappedTimeMs = !this.config.loop
      ? expectedLocalTimeMs
      : expectedLocalTimeMs % this.videoDurationMs;

    if (
      Math.abs(expectedLocalMappedTimeMs - actualLocalTimeMs) >= EPSILON_MS &&
      expectedLocalMappedTimeMs < this.videoDurationMs &&
      Number.isFinite(this.videoDurationMs)
    ) {
      return {
        expectedLocalTimeMs: expectedLocalMappedTimeMs,
        actualLocalTimeMs,
      };
    }
    return null;
  }

  isSeekableTo(localTimeMs: number): boolean {
    if (
      localTimeMs > this.config.durationMs ||
      (this.videoDurationMs !== 0 && localTimeMs > this.videoDurationMs) ||
      !Number.isFinite(this.videoDurationMs)
    )
      return false;

    let canSeek = false;

    for (let i = 0; i < this.internalMedia.seekable.length; i++) {
      const startMs = this.internalMedia.seekable.start(i) * 1000;
      const endMs = this.internalMedia.seekable.end(i) * 1000;
      if (localTimeMs >= startMs && localTimeMs <= endMs) canSeek = true;
    }

    return canSeek;
  }

  nextAudioEvents(
    prevTimelineTimeMs: number,
    nextTimelineTimeMs: number
  ): AudioEventDesc[] {
    const events: AudioEventDesc[] = [];

    const gainEffect = this.config.audioEffects.gain;
    if (gainEffect) {
      for (const gainEvl of gainEffect.trackLocalEnvelopes) {
        // convert to playhead time
        const timelineTimeStartMs =
          this.config.timelineTimeStartMs + gainEvl.start.ms;

        if (
          timelineTimeStartMs >= prevTimelineTimeMs &&
          timelineTimeStartMs < nextTimelineTimeMs
        ) {
          // audio should activate this tick compute where it should be, return {
          // start, end, curve }. NOTE: audio is operating on a different and
          // drastically more-accurate clock than the playhead! As a compromise,
          // this assumes that the audio events can only be started on a playhead
          // quantum boundary.

          const curveDurationMs = gainEvl.end.ms - gainEvl.start.ms;
          const curvePointDurationMs = 1;

          if (gainEvl.curve === 'linear' || gainEvl.curve === 'none') {
            events.push({
              param: 'gain',
              ctxRelativeStartSec: 0,
              ctxRelativeEndSec: curveDurationMs / 1000,
              initialValue: gainEvl.start.value,
              goalValue: gainEvl.end.value,
              curve: gainEvl.curve,
            });
          } else {
            // Scale the curve by this amount.
            const delta = Math.abs(gainEvl.end.value - gainEvl.start.value);
            const curvePointsCount = Math.floor(
              curveDurationMs / curvePointDurationMs
            );
            const curve = new Array<number>(curvePointsCount).fill(0);
            for (let i = 0; i < curve.length; i++) {
              const [fadeInT, fadeOutT] = gainEvl.curve.interp(
                i / curve.length
              );
              const t = gainEvl.curve.direction === 'out' ? fadeOutT : fadeInT;
              curve[i] = t * delta;
            }

            events.push({
              param: 'gain',
              ctxRelativeStartSec: 0,
              ctxRelativeEndSec: curveDurationMs / 1000,
              initialValue: gainEvl.start.value,
              goalValue: gainEvl.end.value,
              curve: curve,
            });
          }
        }
      }
    }

    return events;
  }

  // handle events, load metadata, etc...
  draw(timelineTimeMs: number, target: CanvasRenderingContext2D): number {
    if (this.vfbuffer.width === 0 && this.vfbuffer.height === 0) {
      // Video is likely not loaded, abort.
      return this.renderCount;
    }

    const localTimeMs = timelineTimeMs - this.config.timelineTimeStartMs;
    const targetWidth = target.canvas.width;
    const targetHeight = target.canvas.height;

    let buffers: VideoFrameBuffer[] = [this.vfbuffer];

    // VISUAL PIPELINE: This is a specific order because otherwise the visual
    // operations do not make sense. Applying opacity before greenscreen, for
    // example, would drastically change the greenscreen effect. Opacity should
    // generally always be last.

    const chromakeyConfig = this.config.visualEffects.chromakey;
    const boundingBoxConfig = this.config.visualEffects.boundingBox;
    const opacityConfig = this.config.visualEffects.opacity;

    if (chromakeyConfig && boundingBoxConfig) {
      const processor = this.processors.chromakey;
      processor.setParameters(chromakeyConfig);
      const boundingBoxProcessor = this.processors.boundingBox;
      boundingBoxProcessor.setMaskRect(boundingBoxConfig.rect);
      buffers = processor.process(buffers);
    }

    if (boundingBoxConfig) {
      const processor = this.processors.boundingBox;
      processor.setMaskRect(boundingBoxConfig.rect);
      processor.setBBFit(boundingBoxConfig.fit);
      processor.setBB(boundingBoxConfig.box);
      processor.setFieldSize(targetWidth, targetHeight);
      buffers = processor.process(buffers);
    }

    if (opacityConfig) {
      const processor = this.processors.opacity;

      // TODO: extract as interpolation utility, this is useful for all numbers
      for (const opacity of opacityConfig.trackLocalEnvelopes) {
        const interpolatedTime =
          (localTimeMs - opacity.start.ms) /
          (opacity.end.ms - opacity.start.ms);

        let v: number | undefined;

        if (interpolatedTime >= 0 && interpolatedTime <= 1) {
          // Envelope is active, figure out how much and apply

          // TODO: fix this, the interp TwoTrack thing is just not useful
          const [fadeInT] = opacity.curve.interp(interpolatedTime);

          const t = fadeInT;
          v =
            t * (opacity.end.value - opacity.start.value) + opacity.start.value;
        } else if (interpolatedTime > 1) {
          // Envelope is inactive. But its end value is required for
          // continuity. Imagine a fade out that completes, but then the
          // player is seeked to before the fadeout. How to reset the
          // opacity to 1? The solution is to use the previous envelope's
          // end value, since together the produce a continuous curve. The
          // envelopes are sorted by start time, so it's safe to carry the
          // end value. It will be either overridden by a later or
          // concurrent envelope.
          v = opacity.end.value;
        }

        if (v !== undefined) processor.setOpacity(v);
      }

      buffers = processor.process(buffers);
    }

    const source = buffers[0];
    if (!source) return this.renderCount;

    const sourceWidth = source.width;
    const sourceHeight = source.height;

    const sourceForDrawing = source.asCanvasImageSource();
    if (!sourceForDrawing) return this.renderCount;

    target.drawImage(
      sourceForDrawing,
      0,
      0,
      sourceWidth,
      sourceHeight,
      0,
      0,
      targetWidth,
      targetHeight
    );

    this.renderCount++;
    return this.renderCount;
  }
}
