import { type GLCompatChromakeyParameters } from '@lp-lib/game';

import { getFeatureQueryParamArray } from '../../../../hooks/useFeatureQueryParam';
import { HiddenCanvas } from '../../../../utils/canvas';
import CanvasVideoFrameBuffer from '../../vendor/amazon-chime-sdk-js/CanvasVideoFrameBuffer';
import type VideoFrameBuffer from '../../vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import type VideoFrameProcessor from '../../vendor/amazon-chime-sdk-js/VideoFrameProcessor';
import chromakeyFragmentShaderPathJF from './chroma_jf.frag?url';
import chromakeyFragmentShaderPathV1 from './chroma_v1.frag?url';
import chromakeyFragmentShaderPathV2 from './chroma_v2.frag?url';
import { type IChromakeyProgram } from './IChromakeyProgram';
import { JFChromakeyProgram } from './JFChromakeyProgram';
import { OBSChromakeyProgram } from './OBSChromakeyProgram';

const ShaderVersions = {
  v1: chromakeyFragmentShaderPathV1,
  v2: chromakeyFragmentShaderPathV2,
  jf: chromakeyFragmentShaderPathJF,
};

async function loadShader(version: keyof typeof ShaderVersions) {
  const url = ShaderVersions[version];
  const res =
    import.meta.env.MODE === 'test' ? new Response('') : await fetch(url);
  if (!res.ok)
    throw new Error(`ShaderLoadError: ${res.status} ${res.statusText} ${url}`);
  return res.text();
}

function isOffscreenCanvas(cvs: unknown): cvs is OffscreenCanvas {
  return 'OffscreenCanvas' in window && cvs instanceof OffscreenCanvas;
}

export async function preloadAndCacheChromakeyShader(
  version = getFeatureQueryParamArray('chromakey-processor-version')
): Promise<string> {
  const shader = await loadShader(version);
  ChromakeyProcessor.ShaderCache[version] = shader;
  return shader;
}

setTimeout(() => {
  // Guard to avoid loading during tests
  if (typeof window !== 'undefined' && 'fetch' in window) {
    preloadAndCacheChromakeyShader();
  }
}, 2000);

export class ChromakeyProcessor implements VideoFrameProcessor {
  static ShaderCache = {
    v1: '',
    v2: '',
    jf: '',
  };

  private hcvs = new HiddenCanvas('chromakey-processor');
  private canvasVideoFrameBuffer = new CanvasVideoFrameBuffer(this.hcvs.cvs);
  private prog: IChromakeyProgram;

  public enabled = true;
  private ready = false;
  private destroyed = false;

  private enqueuedParameters: GLCompatChromakeyParameters[] = [];

  constructor(
    version: 'v1' | 'v2' | 'jf' = getFeatureQueryParamArray(
      'chromakey-processor-version'
    )
  ) {
    this.hcvs.attach();

    switch (version) {
      case 'v1':
      case 'v2': {
        this.prog = new OBSChromakeyProgram(version);
        break;
      }

      case 'jf': {
        this.prog = new JFChromakeyProgram();
        break;
      }
    }

    // Load the shader only when a Processor is constructed, and try hard to
    // prevent extraneous requests. The real solution should be to just inline
    // the shaders, they're not large. But CRA doesn't support it. We also don't
    // want external callers to have to wait for the ChromakeyProcessor to be
    // aware that it is async.

    const readify = (shader: string) => {
      this.prog.init(this.canvasVideoFrameBuffer.asCanvasElement(), shader);
      this.ready = true;
    };

    if (ChromakeyProcessor.ShaderCache[version] !== '') {
      readify(ChromakeyProcessor.ShaderCache[version]);
    } else {
      preloadAndCacheChromakeyShader(version)
        .then((shader) => {
          // `readify` occasionally throws due to the canvasVideoFrameBuffer
          // being already destroyed, but it's unclear how since the `.then()`
          // should catch it. Try to avoid the issue by tracking `destroyed`
          // state. See
          // https://sentry.internal.gadder.live/organizations/gadder-live/issues/1139
          if (this.destroyed) return;
          readify(shader);
          this.flushEnqueuedParams();
        })
        .catch((err) => {
          this.ready = false;
          throw err;
        });
    }
  }

  setParameters(parameters: GLCompatChromakeyParameters): void {
    if (!this.ready) {
      this.enqueuedParameters.push({ ...parameters });
      return;
    }

    this.prog.setParameters(
      [...parameters.keyColor, 1],
      parameters.similarity,
      parameters.smoothness,
      parameters.spill
    );
  }

  private flushEnqueuedParams(): void {
    if (!this.ready) return;
    for (const p of this.enqueuedParameters) {
      this.setParameters(p);
    }

    this.enqueuedParameters.length = 0;
  }

  process(buffers: VideoFrameBuffer[]): VideoFrameBuffer[] {
    if (!this.enabled) return buffers;

    // Squelch output until the processor is ready. Otherwise an unprocessed
    // frame or more showing the greenscreen could be seen.
    if (this.ready !== true) return [];

    // for each buffer, ensure dimensions match, process chromakey,

    for (const b of buffers) {
      this.prog.matchDimensionsIfNeeded(b);

      let el = b.asCanvasImageSource();
      if (!el) continue;

      if (isOffscreenCanvas(el)) {
        el = el.transferToImageBitmap();
      }

      if (el instanceof SVGImageElement) {
        throw new Error('SVGImageElement not supported by webgl');
      }

      this.prog.renderFrame(el);
    }

    buffers[0] = this.canvasVideoFrameBuffer;

    return buffers;
  }

  async destroy(): Promise<void> {
    this.hcvs.detach();
    this.prog.destroy();
    this.canvasVideoFrameBuffer.destroy();
    this.destroyed = true;
  }
}
