export function matchDimensionsIfNeeded(
  source: { width: number; height: number },
  target: { width: number; height: number }
): boolean {
  if (source.height !== target.height || source.width !== target.width) {
    target.width = source.width;
    target.height = source.height;
    return true;
  }
  return false;
}
