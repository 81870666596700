import {
  contain,
  cover,
  type IntrinsicScaleCalculator,
  type IntrinsicScaleRect,
} from 'intrinsic-scale';

/**
 * Compute x,y, width, and height for a typical object-fit operation, such as
 * "contain" or "cover". This preserves the aspect ratio.
 *
 * Operations:
 *
 * - "natural-center": natural size of the child, centered within the parent.
 *   Warning: x/y could be negative, width/height can be outside the bounds of
 *   the canvas!
 * - "contain": css object-fit contain
 * - "cover": css object-fit cover
 */
export const FitOperation: {
  [K in FitOperationKind]: IntrinsicScaleCalculator;
} = {
  contain: contain,
  cover: cover,
  'natural-center': naturalCenter,
} as const;

export type FitOperationKind = 'cover' | 'contain' | 'natural-center';

/**
 * Natural size, centered in the parent.
 */
function naturalCenter(
  parentWidth: number,
  parentHeight: number,
  childWidth: number,
  childHeight: number
): IntrinsicScaleRect {
  const destX = Math.floor(parentWidth / 2 - childWidth / 2);
  const destY = Math.floor(parentHeight / 2 - childHeight / 2);

  return {
    width: childWidth,
    height: childHeight,
    x: destX,
    y: destY,
  };
}
