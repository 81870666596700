import { type Logger } from '@lp-lib/logger-base';

import { type IVideoMixerTrack } from '../IVideoMixerTrack';
import { type VideoMixerEmitter } from '../types';
import { fixed4 } from '../utils';

export function trackStopTickHandler(
  prevTime: number,
  nextTime: number,
  track: IVideoMixerTrack,
  _playing: boolean,
  log: Logger,
  emitter: VideoMixerEmitter
): void {
  // Time for track to end?

  const [, endMs] = track.computeActiveTimelineTimeMs();

  if (endMs >= prevTime && endMs < nextTime) {
    if (track.playing) {
      track.pause();
      const drift = endMs - prevTime;
      log.info(`track-end ${track.id}`, {
        playhead: fixed4(prevTime),
        endMs: fixed4(endMs),
        drift: fixed4(drift),
      });
      emitter.emit('track-playstate', track.id, track.playing, prevTime);
      emitter.emit('track-end', track.id, prevTime, drift);
    }
  }
}
