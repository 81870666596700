import { type IChromakeyProgram } from './IChromakeyProgram';
import { createIncompleteWGL } from './IncompleteWGL';

// https://jameshfisher.com/2020/08/11/production-ready-green-screen-in-the-browser/
export class JFChromakeyProgram implements IChromakeyProgram {
  wgl: null | {
    gl: WebGLRenderingContext;
    prog: WebGLProgram;
    locs: JFChromaKeyLocs;
  } = null;

  destroy(): void {
    if (this.wgl) {
      // destroy previous
      this.wgl.gl.deleteProgram(this.wgl.prog);
      this.wgl = null;
    }
  }

  init(cvs: HTMLCanvasElement, fragmentShaderSourceRaw: string): void {
    if (this.wgl) throw new Error('ChromakeyProgramAlreadyInitialized');

    const { gl, prog } = createIncompleteWGL(cvs, fragmentShaderSourceRaw);

    this.wgl = {
      gl,
      prog,
      locs: {
        tex: gl.getUniformLocation(prog, 'tex'),
        texWidth: gl.getUniformLocation(prog, 'texWidth'),
        texHeight: gl.getUniformLocation(prog, 'texHeight'),
        alphaZero: gl.getUniformLocation(prog, 'alphaZero'),
        keyColor: gl.getUniformLocation(prog, 'keyColor'),
        similarity: gl.getUniformLocation(prog, 'similarity'),
        smoothness: gl.getUniformLocation(prog, 'smoothness'),
        spill: gl.getUniformLocation(prog, 'spill'),
      },
    };
  }

  setDimensions(sourceWidth: number, sourceHeight: number): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');

    const { gl } = this.wgl;

    gl.canvas.width = sourceWidth;
    gl.canvas.height = sourceHeight;

    gl.viewport(0, 0, sourceWidth, sourceHeight);
    gl.uniform1f(this.wgl.locs.texWidth, sourceWidth);
    gl.uniform1f(this.wgl.locs.texHeight, sourceHeight);
  }

  matchDimensionsIfNeeded(source: { width: number; height: number }): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');
    if (
      source.width !== this.wgl.gl.canvas.width ||
      source.height !== this.wgl.gl.canvas.height
    )
      this.setDimensions(source.width, source.height);
  }

  setParameters(
    rgba: readonly [number, number, number, number],
    similarity: number,
    smoothness: number,
    spill: number
  ): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');
    const { gl } = this.wgl;

    // Purposefully ignores opacity, shader does not support it.
    const [r, g, b] = rgba;

    gl.uniform3f(this.wgl.locs.keyColor, r, g, b);
    gl.uniform1f(this.wgl.locs.similarity, similarity);
    gl.uniform1f(this.wgl.locs.smoothness, smoothness);
    gl.uniform1f(this.wgl.locs.spill, spill);
  }

  renderFrame(source: TexImageSource): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');
    const { gl } = this.wgl;

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, source);

    gl.uniform1i(this.wgl.locs.tex, 0);
    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
  }
}

interface JFChromaKeyLocs {
  tex: null | WebGLUniformLocation;
  texWidth: null | WebGLUniformLocation;
  texHeight: null | WebGLUniformLocation;
  alphaZero: null | WebGLUniformLocation;
  keyColor: null | WebGLUniformLocation;
  similarity: null | WebGLUniformLocation;
  smoothness: null | WebGLUniformLocation;
  spill: null | WebGLUniformLocation;
}
