import { type HiddenCanvas } from '../../utils/canvas';
import CanvasVideoFrameBuffer from './vendor/amazon-chime-sdk-js/CanvasVideoFrameBuffer';

export class DrawableCanvasVideoFrameBuffer extends CanvasVideoFrameBuffer {
  readonly ctx = this.asCanvasElement().getContext('2d');

  constructor(private hcvs: HiddenCanvas) {
    super(hcvs.cvs);
    this.hcvs.attach();
  }

  destroy(): void {
    this.hcvs.detach();
  }
}
