import type VideoFrameBuffer from './vendor/amazon-chime-sdk-js/VideoFrameBuffer';

const ERR_DESTROYED_MSG = 'CanvasImageSourceFrameBuffer is destroyed';

export class CanvasImageSourceFrameBuffer implements VideoFrameBuffer {
  framerate = 0;

  constructor(
    // Note: sorry, no SVG allowed, it's just too different.
    private source:
      | HTMLCanvasElement
      | HTMLImageElement
      | HTMLVideoElement
      | HTMLAudioElement
      | null
  ) {}

  get width(): number {
    if (!this.source) {
      throw new Error(ERR_DESTROYED_MSG);
    }
    return this.source instanceof HTMLVideoElement
      ? this.source.videoWidth
      : this.source instanceof HTMLAudioElement
      ? 0
      : this.source.width;
  }

  set width(_w: number) {
    throw new Error('width is readonly');
  }

  get height(): number {
    if (!this.source) {
      throw new Error(ERR_DESTROYED_MSG);
    }
    return this.source instanceof HTMLVideoElement
      ? this.source.videoHeight
      : this.source instanceof HTMLAudioElement
      ? 0
      : this.source.height;
  }

  set height(_h: number) {
    throw new Error('height is readonly');
  }

  destroy(): void {
    this.source = null;
  }

  asCanvasImageSource(): CanvasImageSource | null {
    if (!this.source) {
      throw new Error(ERR_DESTROYED_MSG);
    }

    if (this.source instanceof HTMLAudioElement) return null;

    return this.source;
  }
}
