import { assertExhaustive } from '../../utils/common';
import {
  requestVideoFrameCallbackAvailable,
  type RequestVideoFrameCallbackCallback,
} from '../../utils/requestVideoFrameCallback';
import { createGameLoop } from '../PixelFx/GameLoop';

export type LoopCanceler = () => void;

export type LoopConfig =
  | {
      kind: 'raf';
      tick: () => void;
      fps: number;
    }
  | {
      kind: 'timer';
      tick: () => void;
      fps: number;
    }
  | {
      kind: 'raf-accumulated';
      update: (dt: number) => void;
      draw: (interp: number, dt: number) => void;
      updateTime: number;
      drawTime: number;
      panicAt: number;
    }
  | {
      kind: 'timer-accumulated';
      update: (dt: number) => void;
      draw: (interp: number, dt: number) => void;
      updateTime: number;
      drawTime: number;
      panicAt: number;
    }
  | {
      kind: 'rvfcb';
      tick: RequestVideoFrameCallbackCallback;
      video: HTMLVideoElement;
    };

function rafLoop(tick: () => void, fps: number): LoopCanceler {
  let id = 0;
  let lastTickTime = 0;
  const fpsInterval = 1000 / fps;
  const tickWrap = (time: DOMHighResTimeStamp) => {
    id = requestAnimationFrame(tickWrap);
    const elapsed = time - lastTickTime;
    if (fpsInterval === 0 || elapsed > fpsInterval) {
      tick();
    }
    if (fpsInterval !== 0) {
      lastTickTime = time - (elapsed % fpsInterval);
    }
  };
  id = requestAnimationFrame(tickWrap);
  return () => {
    cancelAnimationFrame(id);
  };
}

function timerLoop(tick: () => void, fps: number): LoopCanceler {
  const fpsInterval = 1000 / fps;
  const id = setInterval(tick, fpsInterval);
  return () => {
    clearInterval(id);
  };
}

function rvfcbLoop(config: {
  tick: RequestVideoFrameCallbackCallback;
  video: HTMLVideoElement;
}) {
  if (!requestVideoFrameCallbackAvailable(config.video))
    throw new Error(
      'requestVideoFrameCallback is not available in this environment!'
    );

  // Reassign to allow TS to keep it narrowed.
  const rvfcbv = config.video;

  let id = 0;

  const wrap: RequestVideoFrameCallbackCallback = (...args) => {
    id = rvfcbv.requestVideoFrameCallback(wrap);
    config.tick(...args);
  };

  id = rvfcbv.requestVideoFrameCallback(wrap);
  return () => {
    rvfcbv.cancelVideoFrameCallback(id);
  };
}

function accumulatedRafLoop(config: {
  update: (dt: number) => void;
  draw: (interp: number, dt: number) => void;
  updateTime: number;
  drawTime: number;
  panicAt: number;
}) {
  const ctl = createGameLoop(config);
  return ctl.stop;
}

function accumulatedTimerLoop(config: {
  update: (dt: number) => void;
  draw: (interp: number, dt: number) => void;
  updateTime: number;
  drawTime: number;
  panicAt: number;
}) {
  // aim for 60 ticks per second, just like requestAnimationFrame
  const ms = 1000 / 60;
  const ctl = createGameLoop({
    ...config,
    accumulatorTick: (accumulate) =>
      setTimeout(accumulate, ms) as unknown as number,
    cancelAccumulatorTick: (handle) => clearTimeout(handle),
  });
  return ctl.stop;
}

export function createLoop(config: LoopConfig): LoopCanceler {
  switch (config.kind) {
    case 'raf':
      return rafLoop(config.tick, config.fps);
    case 'timer':
      return timerLoop(config.tick, config.fps);
    case 'raf-accumulated':
      return accumulatedRafLoop(config);
    case 'timer-accumulated':
      return accumulatedTimerLoop(config);
    case 'rvfcb': {
      return rvfcbLoop(config);
    }
    default:
      assertExhaustive(config);
      throw new Error(
        `unsupported loop implementation: ${(config as LoopConfig).kind}`
      );
  }
}

export function createLoopWithTechniquePriority<C extends LoopConfig>(
  priorities: C[],
  tests: { [K in C['kind']]: () => boolean }
): LoopCanceler {
  for (const config of priorities) {
    const test = tests[config.kind as C['kind']];
    if (test && test()) return createLoop(config);
  }

  throw new Error(
    `No Tested Loop Technique Found. Tried: ${priorities
      .map((c) => c.kind)
      .join(',')}`
  );
}
