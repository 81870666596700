import { type BoundingBox, type RelativeRect } from '@lp-lib/game';

import { HiddenCanvas } from '../../../utils/canvas';
import { DrawableCanvasVideoFrameBuffer } from '../DrawableCanvasVideoFrameBuffer';
import { FitOperation, type FitOperationKind } from '../FitOperation';
import { calcCanvasDrawable } from '../utils/calcCanvasDrawable';
import { matchDimensionsIfNeeded } from '../utils/matchDimensionsIfNeeded';
import type VideoFrameBuffer from '../vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import type VideoFrameProcessor from '../vendor/amazon-chime-sdk-js/VideoFrameProcessor';

export class BoundingBoxProcessor implements VideoFrameProcessor {
  private canvasVideoFrameBuffer = new DrawableCanvasVideoFrameBuffer(
    new HiddenCanvas('bounding-box-processor')
  );

  public enabled = true;
  public maskRectEnabled = true;
  public boundingBoxDrawEnabled = false;

  constructor(
    private maskRect: RelativeRect = { top: 0, right: 0, bottom: 0, left: 0 },
    private box: BoundingBox = { x: 0, y: 0, width: 1, height: 1 },
    private field: BoundingBox = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    private boxFit: FitOperationKind = 'cover'
  ) {}

  setFieldSize(widthPx: number, heightPx: number): void {
    // pixel dimensions of the "world", aka the final output size of all the processors.
    // 0,0 is upper left.
    this.field.width = widthPx;
    this.field.height = heightPx;
  }

  setBB(box: BoundingBox): void {
    this.box = box;
  }

  setBBPosition(xPct: number, yPct: number): void {
    // x/y offset (upper left corner) from the scene
    // 0,0 is upper left corner
    // 1,1 is lower right corner of the scene
    this.box.x = xPct;
    this.box.y = yPct;
  }

  setBBSize(wPct: number, hPct: number): void {
    // width/height as a percentage of the Scene
    this.box.width = wPct;
    this.box.height = hPct;
  }

  setBBFit(fit: FitOperationKind): void {
    // given the position and size, that creates a bounding box
    // Then the choice is:
    // - squish the incoming signal into the box
    // - cover the box
    // - be contained by the box
    // - natural center
    this.boxFit = fit;
  }

  setMaskRect(rect: RelativeRect): void {
    // top/right/bottom/left pct from the source (incoming) buffer
    this.maskRect = rect;
  }

  process(buffers: VideoFrameBuffer[]): VideoFrameBuffer[] {
    const ctx = this.canvasVideoFrameBuffer.ctx;

    if (
      !this.field.width ||
      !this.field.height ||
      !this.box.width ||
      !this.box.height ||
      !this.enabled ||
      !ctx
    )
      return buffers;

    ctx.clearRect(
      0,
      0,
      this.canvasVideoFrameBuffer.width,
      this.canvasVideoFrameBuffer.height
    );

    matchDimensionsIfNeeded(this.field, this.canvasVideoFrameBuffer);

    for (const b of buffers) {
      const { sx, sy, sw, sh, destX, destY, destW, destH } = calcCanvasDrawable(
        this.box,
        FitOperation[this.boxFit],
        this.field,
        { width: b.width, height: b.height },
        this.maskRectEnabled
          ? this.maskRect
          : { top: 0, right: 0, bottom: 0, left: 0 }
      );

      // Draw from crop to crop!
      const source = b.asCanvasImageSource();
      if (!source) continue;

      ctx.clearRect(0, 0, this.field.width, this.field.height);
      ctx.drawImage(source, sx, sy, sw, sh, destX, destY, destW, destH);

      if (this.boundingBoxDrawEnabled) {
        const boundingBoxPx: BoundingBox = {
          x: this.box.x * this.field.width,
          y: this.box.y * this.field.height,
          width: this.box.width * this.field.width,
          height: this.box.height * this.field.height,
        };
        ctx.strokeStyle = '#01ACC4';
        ctx.lineWidth = 2;
        ctx.strokeRect(
          boundingBoxPx.x,
          boundingBoxPx.y,
          boundingBoxPx.width,
          boundingBoxPx.height
        );
      }
    }

    buffers[0] = this.canvasVideoFrameBuffer;

    return buffers;
  }

  async destroy(): Promise<void> {
    this.canvasVideoFrameBuffer.destroy();
  }
}
