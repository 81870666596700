import { VideoMixerTrack } from './VideoMixerTrack';

export class VideoMixerAudioTrack extends VideoMixerTrack {
  sourceDescription(): string {
    const sup = super.sourceDescription(false);
    return `AudioTrack${sup}`;
  }
  draw(): number {
    return 0;
  }
}
