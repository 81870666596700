import { WebAudioPool } from '../../utils/unplayable';
import { VideoMixer } from '../VideoMixer';
import { MediaElementTracker } from '../VideoMixer/MediaElementTracker';

export function makeLocalAudioOnlyVideoMixer(_pool: 'pool' | 'no-pool') {
  const vm = new MediaElementTracker({}, false, 'timer-accumulated');

  vm.on('track-remove', (_trackId, _playheadMs, element) => {
    if (!element) return;
    WebAudioPool.GetOptionalInstance()?.release(element);
  });

  return [vm] as const;
}

export function makeAudioOnlyVideoMixer(pool: 'pool' | 'no-pool') {
  const vm = new VideoMixer(
    {
      renderHeight: 100,
      renderWidth: 100,
      audioRenderingOnly: true,
      cleanupMESN: pool === 'pool' ? false : true,
    },
    false,
    'timer-accumulated'
  );

  vm.on('track-remove', (_trackId, _playheadMs, element) => {
    if (!element) return;
    WebAudioPool.GetOptionalInstance()?.release(element);
  });

  return vm;
}
