import { type Logger } from '@lp-lib/logger-base';

import { type VideoMixerEmitter } from '../types';
import { fixed4 } from '../utils';
import { type VideoMixerMarker } from '../VideoMixerMarker';

export function markerReachedTickHandler(
  prevTime: number,
  nextTime: number,
  marker: VideoMixerMarker,
  playing: boolean,
  log: Logger,
  emitter: VideoMixerEmitter
): void {
  const startMs = marker.config.timelineTimeStartMs;

  if (playing && startMs >= prevTime && startMs < nextTime) {
    const drift = startMs - prevTime;
    log.info(`marker-reached ${marker.id}`, {
      playhead: fixed4(prevTime),
      timelineTimeStartMs: fixed4(startMs),
      drift: fixed4(drift),
    });
    emitter.emit(
      'marker-reached',
      marker.id,
      marker.config.extra,
      prevTime,
      drift
    );
  }
}
