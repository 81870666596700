import {
  defaultGreenScreenSettings,
  GreenScreenValuesUtils,
} from '@lp-lib/game';

import { EnergyPreservingCrossfade } from './presets';
import {
  type BoundingBoxEffectConfig,
  type ChromakeyEffectConfig,
  type GainEffectConfig,
  type OpacityEffectConfig,
  type TrackInitConfig,
  type TwoTrackCurve,
} from './types';

abstract class Builder<InputT extends BuiltT, BuiltT = InputT> {
  /**
   * The default values are defined here in each concrete instance
   */
  abstract fields: InputT;

  /**
   * Whether the defaults have ever been changed. If implementing custom write
   * methods, be sure to mark this as true within those methods!
   */
  dirty = false;

  set(opts?: Partial<InputT>) {
    if (opts) {
      this.dirty = true;
    }
    this.fields = {
      ...this.fields,
      ...opts,
    };
    return this;
  }

  /**
   * Always returns a value, even if no values have ever been written by the
   * owner of this instance.
   */
  buildWithDefaults(): BuiltT {
    return this.fields;
  }

  /**
   * Only returns a value if the values have been purposefully changed from the
   * defaults.
   */
  build(): BuiltT | null {
    const built = this.buildWithDefaults();
    if (this.dirty) return built;
    return null;
  }
}

class BoundingBoxEffectConfigBuilder extends Builder<BoundingBoxEffectConfig | null> {
  fields = {
    kind: 'bounding-box' as const,
    rect: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    box: { width: 1, height: 1, x: 0, y: 0 },
    fit: 'cover' as const,
  };
}

class OpacityEffectConfigBuilder extends Builder<OpacityEffectConfig> {
  fields: OpacityEffectConfig = {
    kind: 'opacity' as const,
    trackLocalEnvelopes: [],
  };

  push(envelope: OpacityEffectConfig['trackLocalEnvelopes'][number]) {
    this.dirty = true;
    this.fields.trackLocalEnvelopes.push(envelope);
    return this;
  }
}

class ChromakeyEffectConfigBuilder extends Builder<ChromakeyEffectConfig> {
  fields: ChromakeyEffectConfig = {
    kind: 'chromakey' as const,
    ...GreenScreenValuesUtils.ToGLCompat(defaultGreenScreenSettings()),
  };
}

class AudioGainEffectConfigBuilder extends Builder<GainEffectConfig> {
  fields: GainEffectConfig = {
    kind: 'gain' as const,
    trackLocalEnvelopes: [],
  };

  push(envelope: GainEffectConfig['trackLocalEnvelopes'][number]) {
    this.dirty = true;
    this.fields.trackLocalEnvelopes.push(envelope);
    return this;
  }
}

export class TrackInitConfigBuilder {
  private visualConfigs = {
    chromakey: new ChromakeyEffectConfigBuilder(),
    boundingBox: new BoundingBoxEffectConfigBuilder(),
    opacity: new OpacityEffectConfigBuilder(),
  } as const;

  private audioConfigs = {
    gain: new AudioGainEffectConfigBuilder(),
  };

  constructor(
    private config: TrackInitConfig = {
      timelineTimeStartMs: 0,
      timelineTimeEndMs: Infinity,
      durationMs: 0,
      loop: false,
      visualEffects: {},
      audioEffects: {},
      zLayer: 0,
    }
  ) {}

  private _built = false;
  private hasTimelineTimeStartMs = false;

  get built(): boolean {
    return this._built;
  }

  private assertWritable() {
    if (this._built) throw new Error('Cannot modify, already built');
  }

  build(): TrackInitConfig {
    if (this.config.durationMs === 0)
      throw new Error('durationMs cannot be zero');
    if (!this.hasTimelineTimeStartMs)
      throw new Error('timelineTimeStartMs cannot be empty or implicit');
    if (!isFinite(this.config.durationMs) && this.config.loop === true) {
      throw new Error('a `loop: true` track cannot have an infinite duration');
    }
    if (this._built) throw new Error('Cannot rebuild');
    this._built = true;

    // VISUAL PIPELINE

    const chromakey = this.visualConfigs.chromakey.build();
    if (chromakey) this.config.visualEffects.chromakey = chromakey;

    // BoundingBox uses defaults. It must always be active since it manages
    // "fit: cover" for differently-sized videos.
    const boundingBox = this.visualConfigs.boundingBox.buildWithDefaults();
    if (boundingBox) this.config.visualEffects.boundingBox = boundingBox;

    const opacity = this.visualConfigs.opacity.build();
    if (opacity) {
      // Envelopes must be sorted in order to ensure curves are predictable
      opacity.trackLocalEnvelopes.sort((a, b) => a.start.ms - b.start.ms);
      this.config.visualEffects.opacity = opacity;
    }

    // AUDIO PIPELINE

    const gains = this.audioConfigs.gain.build();
    if (gains) this.config.audioEffects.gain = gains;

    return this.config;
  }

  /**
   * When the track should start.
   */
  setTimelineTimeStartMs(ms: number): this {
    this.assertWritable();
    this.hasTimelineTimeStartMs = true;
    this.config.timelineTimeStartMs = ms;
    return this;
  }

  /**
   * When the track should end, overriding its own duration and `loop`.
   */
  setTimelineTimeEndMs(ms: number): this {
    this.assertWritable();
    this.config.timelineTimeEndMs = ms;
    return this;
  }

  setDurationMs(ms: number): this {
    this.assertWritable();
    this.config.durationMs = ms;
    return this;
  }

  /**
   * Whether the track should loop infinitely, and thus always be active /
   * playing unless manually removed.
   */
  setLoop(loop: boolean): this {
    this.assertWritable();
    this.config.loop = loop;
    return this;
  }

  /**
   * Denote the sort order for this track. Greater values are drawn _later_
   * than lower values (e.g. on top of).
   */
  setZLayer(z: number): this {
    this.assertWritable();
    this.config.zLayer = z;
    return this;
  }

  /**
   * Add a fade in or out to the audio associated with the track.
   */
  addAudioGainEnvelope(
    trackLocalStart: { ms: number; value: number },
    trackLocalEnd: { ms: number; value: number },
    curve: GainEffectConfig['trackLocalEnvelopes'][number]['curve']
  ): this {
    this.assertWritable();
    this.audioConfigs.gain.push({
      start: trackLocalStart,
      end: trackLocalEnd,
      curve,
    });
    return this;
  }

  /**
   * Add an opacity envelope to the list of existing video envelopes. The
   * presence of an opacity envelope within a VideoMixerTrack will initialize
   * the Opacity Processor
   */
  addVideoOpacityEnvelope(
    trackLocalStart: { ms: number; value: number },
    trackLocalEnd: { ms: number; value: number },
    direction: 'in' | 'out',
    curve: TwoTrackCurve = EnergyPreservingCrossfade
  ): this {
    this.assertWritable();
    this.visualConfigs.opacity.push({
      start: trackLocalStart,
      end: trackLocalEnd,
      curve: { ...curve, direction },
    });
    return this;
  }

  /**
   * Add an chromakey envelope to the list of existing video envelopes. The
   * presence of an chromakey envelope within a VideoMixerTrack will initialize
   * the Chromakey Processor.
   */
  setChromakey(...args: Parameters<ChromakeyEffectConfigBuilder['set']>): this {
    this.assertWritable();
    this.visualConfigs.chromakey.set(...args);
    return this;
  }

  /**
   * Convenience to help remember to independently set the maskPct value for
   * masking via the BoundingBoxProcessor.
   */
  setRectMask(rect: BoundingBoxEffectConfig['rect']): this {
    return this.setBoundingBox({ rect });
  }

  /**
   * Set any field of the BoundingBoxProcessor, independently. It is always
   * enabled.
   */
  setBoundingBox(
    ...args: Parameters<BoundingBoxEffectConfigBuilder['set']>
  ): this {
    this.assertWritable();
    this.visualConfigs.boundingBox.set(...args);
    return this;
  }
}
