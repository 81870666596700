import { type IChromakeyProgram } from './IChromakeyProgram';
import { createIncompleteWGL, type IncompleteWGL } from './IncompleteWGL';

type WGL =
  | (IncompleteWGL & { version: 'v2'; locs: OBSChromaKeyCommonLocs })
  | (IncompleteWGL & { version: 'v1'; locs: OBSChromaKeyV1Locs });

export class OBSChromakeyProgram implements IChromakeyProgram {
  wgl: null | WGL = null;

  constructor(public version: 'v1' | 'v2') {}

  destroy(): void {
    if (this.wgl) {
      // destroy previous
      this.wgl.gl.deleteProgram(this.wgl.prog);
      this.wgl = null;
    }
  }

  init(cvs: HTMLCanvasElement, fragmentShaderSourceRaw: string): void {
    if (this.wgl) throw new Error('ChromakeyProgramAlreadyInitialized');

    const { gl, prog } = createIncompleteWGL(cvs, fragmentShaderSourceRaw);

    const common = {
      tex: gl.getUniformLocation(prog, 'tex'),
      texWidth: gl.getUniformLocation(prog, 'texWidth'),
      texHeight: gl.getUniformLocation(prog, 'texHeight'),

      opacity: gl.getUniformLocation(prog, 'opacity'),
      contrast: gl.getUniformLocation(prog, 'contrast'),
      brightness: gl.getUniformLocation(prog, 'brightness'),
      gamma: gl.getUniformLocation(prog, 'gamma'),

      keyColor: gl.getUniformLocation(prog, 'keyColor'),

      pixel_size: gl.getUniformLocation(prog, 'pixel_size'),
      cb_v4: gl.getUniformLocation(prog, 'cb_v4'),
      cr_v4: gl.getUniformLocation(prog, 'cr_v4'),

      similarity: gl.getUniformLocation(prog, 'similarity'),
      smoothness: gl.getUniformLocation(prog, 'smoothness'),
      spill: gl.getUniformLocation(prog, 'spill'),
    };

    this.wgl =
      this.version === 'v1'
        ? {
            gl,
            prog,
            version: this.version,
            locs: {
              ...common,
              yuv_mat: gl.getUniformLocation(prog, 'yuv_mat'),
            },
          }
        : {
            gl,
            prog,
            version: this.version,
            locs: common,
          };
  }

  setDimensions(sourceWidth: number, sourceHeight: number): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');

    const { gl } = this.wgl;

    gl.canvas.width = sourceWidth;
    gl.canvas.height = sourceHeight;

    gl.viewport(0, 0, sourceWidth, sourceHeight);
    gl.uniform1f(this.wgl.locs.texWidth, sourceWidth);
    gl.uniform1f(this.wgl.locs.texHeight, sourceHeight);

    // https://github.com/obsproject/obs-studio/blob/8212cedf0365e081f9fd855f0dec006516fd8d6e/plugins/obs-filters/chroma-key-filter.c#L378
    gl.uniform2f(this.wgl.locs.pixel_size, 1 / sourceWidth, 1 / sourceHeight);
  }

  matchDimensionsIfNeeded(source: { width: number; height: number }): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');
    if (
      source.width !== this.wgl.gl.canvas.width ||
      source.height !== this.wgl.gl.canvas.height
    )
      this.setDimensions(source.width, source.height);
  }

  setParameters(
    rgba: readonly [number, number, number, number],
    similarity: number,
    smoothness: number,
    spill: number
  ): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');
    const { gl } = this.wgl;

    const [r, g, b, a] = rgba;

    gl.uniform4f(this.wgl.locs.keyColor, r, g, b, a);
    gl.uniform1f(this.wgl.locs.similarity, similarity);
    gl.uniform1f(this.wgl.locs.smoothness, smoothness);
    gl.uniform1f(this.wgl.locs.spill, spill);

    // TODO: expose these in the UI
    // https://github.com/obsproject/obs-studio/blob/8212cedf0365e081f9fd855f0dec006516fd8d6e/plugins/obs-filters/chroma-key-filter.c#L472
    // v1: opacity: not used by shader directly, defaults 100, 0-100, and is (incorrectly) multiplied by the alpha channel of FFFFFF color, I believe...
    // v2: opacity:
    // min: 0, max: 1, step: 0.0001, default: 1
    gl.uniform1f(this.wgl.locs.opacity, 1);

    // v1: contrast:
    // min: -1.0, max: 1.0, step: 0.01, default: 0
    // v2: contrast:
    // min: -4.0, max: 4.0, step: 0.01, default: 0
    // v2: brightness
    // min: -1.0, max: 1.0, step: 0.0001, default: 0
    // v2: gamma
    // min: -1.0, max: 1.0, step: 0.01, default: 0
    // They need to be mapped: https://github.com/obsproject/obs-studio/blob/8212cedf0365e081f9fd855f0dec006516fd8d6e/plugins/obs-filters/chroma-key-filter.c#L124-L134
    const contrast = 0;
    const mappedContrast =
      contrast < 0.0 ? 1.0 / (-contrast + 1.0) : contrast + 1.0;

    const gamma = 0;
    const mappedGamma = gamma < 0.0 ? -gamma + 1.0 : 1.0 / (gamma + 1.0);

    gl.uniform1f(this.wgl.locs.contrast, mappedContrast);
    gl.uniform1f(this.wgl.locs.brightness, 0);
    gl.uniform1f(this.wgl.locs.gamma, mappedGamma);

    // TODO: Not really necessary to ever change these, might be worth putting during `init`
    gl.uniform4f(this.wgl.locs.cb_v4, -0.100644, -0.338572, 0.439216, 0.501961);
    gl.uniform4f(this.wgl.locs.cr_v4, 0.439216, -0.398942, -0.040274, 0.501961);

    if (this.wgl.version === 'v1') {
      gl.uniformMatrix4fv(
        this.wgl.locs.yuv_mat,
        false,
        [
          // Comments are for readability / defeat prettier slightly since this is
          // a 4x4 matrix.
          0.18258,
          -0.100644, 0.439216, 0.0,
          // x
          0.614231, -0.338572, -0.398942, 0.0,
          // x
          0.062007, 0.439216, -0.040274, 0.0,
          // x
          0.062745, 0.501961, 0.501961, 1.0,
        ]
      );
    }
  }

  renderFrame(source: TexImageSource): void {
    if (!this.wgl) throw new Error('ChromakeyProgramNotInitialized');
    const { gl } = this.wgl;

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, source);

    gl.uniform1i(this.wgl.locs.tex, 0);
    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
  }
}

interface OBSChromaKeyCommonLocs {
  tex: null | WebGLUniformLocation;
  texWidth: null | WebGLUniformLocation;
  texHeight: null | WebGLUniformLocation;

  opacity: null | WebGLUniformLocation;
  contrast: null | WebGLUniformLocation;
  brightness: null | WebGLUniformLocation;
  gamma: null | WebGLUniformLocation;

  keyColor: null | WebGLUniformLocation; // vec4

  pixel_size: null | WebGLUniformLocation; // vec2
  cb_v4: null | WebGLUniformLocation; // vec4
  cr_v4: null | WebGLUniformLocation; // vec4

  similarity: null | WebGLUniformLocation;
  smoothness: null | WebGLUniformLocation;
  spill: null | WebGLUniformLocation;
}

interface OBSChromaKeyV1Locs extends OBSChromaKeyCommonLocs {
  yuv_mat: null | WebGLUniformLocation; // mat4
}
