import { type Logger } from '@lp-lib/logger-base';

import { type IVideoMixerTrack } from '../IVideoMixerTrack';
import { type VideoMixerEmitter } from '../types';
import { fixed4 } from '../utils';

export function trackSafeToRemoveTickHandler(
  prevTime: number,
  _nextTime: number,
  track: IVideoMixerTrack,
  _playing: boolean,
  log: Logger,
  emitter: VideoMixerEmitter,
  autoRemoveTracksAfterSafeMs: number
): void {
  const [, endMs] = track.computeActiveTimelineTimeMs();

  // Time to notify that track is safe to remove?

  if (prevTime - endMs > autoRemoveTracksAfterSafeMs) {
    log.info(`track-safe-to-remove ${track.id}`, {
      playhead: fixed4(prevTime),
      endMs: fixed4(endMs),
      trackSafeToRemoveMs: autoRemoveTracksAfterSafeMs,
    });
    emitter.emit('track-safe-to-remove', track.id, prevTime);
  }
}
