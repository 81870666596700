import { HiddenCanvas } from '../../../utils/canvas';
import { DrawableCanvasVideoFrameBuffer } from '../DrawableCanvasVideoFrameBuffer';
import { matchDimensionsIfNeeded } from '../utils/matchDimensionsIfNeeded';
import type VideoFrameBuffer from '../vendor/amazon-chime-sdk-js/VideoFrameBuffer';
import type VideoFrameProcessor from '../vendor/amazon-chime-sdk-js/VideoFrameProcessor';

export class OpacityProcessor implements VideoFrameProcessor {
  private canvasVideoFrameBuffer = new DrawableCanvasVideoFrameBuffer(
    new HiddenCanvas('opacity-processor')
  );

  public enabled = true;

  constructor(private opacity = 1) {}

  setOpacity(opacity: number): void {
    this.opacity = opacity;
    if (!this.canvasVideoFrameBuffer.ctx) return;
    this.canvasVideoFrameBuffer.ctx.globalAlpha = this.opacity ?? 1;
  }

  process(buffers: VideoFrameBuffer[]): VideoFrameBuffer[] {
    const ctx = this.canvasVideoFrameBuffer.ctx;
    if (!ctx || !this.enabled) return buffers;
    ctx.clearRect(
      0,
      0,
      this.canvasVideoFrameBuffer.width,
      this.canvasVideoFrameBuffer.height
    );

    for (const b of buffers) {
      if (matchDimensionsIfNeeded(b, this.canvasVideoFrameBuffer)) {
        // If dimensions change, globalAlpha (and context state in general) is
        // reset! Reapply current opacity.
        this.setOpacity(this.opacity);
      }
      const width = this.canvasVideoFrameBuffer.width;
      const height = this.canvasVideoFrameBuffer.height;

      const source = b.asCanvasImageSource();
      if (!source) continue;

      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(source, 0, 0, width, height, 0, 0, width, height);
    }

    buffers[0] = this.canvasVideoFrameBuffer;

    return buffers;
  }

  async destroy(): Promise<void> {
    this.canvasVideoFrameBuffer.destroy();
  }
}
