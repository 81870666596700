import { getStaticAssetPath } from '../../utils/assets';
import { type SoundEffect } from './types';

const SFX_MAP = {
  joinTeam: {
    url: getStaticAssetPath('audios/v2/join-team.mp3'),
    volume: 0.3,
    desc: 'Someone joined a team',
  },
  leaveTeam: {
    url: getStaticAssetPath('audios/v2/leave-team.mp3'),
    volume: 0.3,
    desc: 'Someone left a team',
  },
  startStream: {
    url: getStaticAssetPath('audios/v2/start-stream.mp3'),
    volume: 0.5,
    desc: 'Host starts a stream',
  },
  gainPointsHit: {
    url: getStaticAssetPath('audios/v2/gain-points-hit-ding-quickattack.mp3'),
    volume: 0.1,
    desc: 'Gain points hit ding quick attack',
  },
  gainPointsDone: {
    url: getStaticAssetPath('audios/v2/gain-points-done.mp3'),
    volume: 0.1,
    desc: 'Gain points completed',
  },
  everyoneVotes: {
    url: getStaticAssetPath('audios/v2/everyone-votes-sfx.mp3'),
    volume: 0.3,
    desc: 'Everyone votes in Creative Prompt Block',
    delayMs: 250,
  },
  go: {
    url: getStaticAssetPath('audios/v2/go-sfx.mp3'),
    volume: 0.3,
    desc: 'Go animation',
    delayMs: 250,
  },
  xTimesPoints: {
    url: getStaticAssetPath('audios/v2/x-times-points-sfx.mp3'),
    volume: 0.4,
    desc: '2x/3x points multiplier',
  },
  teamInvite: {
    url: getStaticAssetPath('audios/v2/team-invite.mp3'),
    volume: 0.4,
    desc: 'Someone gets invited into a team',
  },
  memoryMatchCardFlipForward: {
    url: getStaticAssetPath('audios/v2/card-flip-forward.mp3'),
    volume: 1,
    desc: 'Card gets revealed in Memory Match Block',
  },
  memoryMatchCardFlipBackward: {
    url: getStaticAssetPath('audios/v2/card-flip-backward.mp3'),
    volume: 0.25,
    desc: 'Card gets closed in Memory Match Block',
  },
  memoryMatchCorrectMatch: {
    url: getStaticAssetPath('audios/v2/rapid-answering-correct.mp3'),
    volume: 0.3,
    desc: 'Player gets a correct match',
  },
  beep: {
    url: getStaticAssetPath('audios/v2/beep.mp3'),
    volume: 0.5,
    desc: 'Beep sound in countdown 3, 2, 1.',
  },
  beepEnd: {
    url: getStaticAssetPath('audios/v2/beep-end.mp3'),
    volume: 0.5,
    desc: 'Timesup',
  },
  showSubmissions: {
    url: getStaticAssetPath('audios/v2/show-submissions-sfx.mp3'),
    volume: 0.5,
    desc: 'Show submissions in Creative Prompt Block',
  },
  showResults: {
    url: getStaticAssetPath('audios/v2/show-results-sfx.mp3'),
    volume: 0.5,
    desc: 'Reveal results',
  },
  missionCompleted: {
    url: getStaticAssetPath('audios/v2/team-relay-game-completed.mp3'),
    volume: 0.5,
    desc: 'Mission completed',
  },
  teamRelayHit: {
    url: getStaticAssetPath('audios/v2/team-relay-hit.mp3'),
    volume: 0.4,
    desc: 'Key hit in Team Relay',
  },
  teamRelayMiss: {
    url: getStaticAssetPath('audios/v2/team-relay-miss.mp3'),
    volume: 0.4,
    desc: 'Key miss in Team Relay',
  },
  teamRelaySequenceFinished: {
    url: getStaticAssetPath('audios/v2/team-relay-finished.mp3'),
    volume: 0.4,
    desc: 'A sequence is completed in Team Relay',
  },
  teamRelayReverse: {
    url: getStaticAssetPath('audios/v2/team-relay-reverse.mp3'),
    volume: 1,
    desc: 'A sequence gets reversed in Team Relay',
  },
  rapidCorrect: {
    url: getStaticAssetPath('audios/v2/rapid-answering-correct.mp3'),
    volume: 0.3,
    desc: 'A correct submission in Rapid Submission Block',
  },
  rapidSlightCorrect: {
    url: getStaticAssetPath('audios/v2/rapid-answering-correct.mp3'),
    volume: 0.2,
    desc: 'A correct submission from the teammates in everyone submit mode',
  },
  rapidWrong: {
    url: getStaticAssetPath('audios/v2/rapid-answering-wrong.mp3'),
    volume: 0.25,
    desc: 'A wrong submission in Rapid Submission Block',
  },
  rapidDuplicate: {
    url: getStaticAssetPath('audios/v2/rapid-answering-duplicate.mp3'),
    volume: 0.25,
    desc: 'A duplicate submission in Rapid Submission Block',
  },
  teamRandomize: {
    url: getStaticAssetPath('audios/v2/randomizing.mp3'),
    volume: 0.3,
    desc: 'Team randomizer is running',
    loop: true,
  },
  teamRandomizeDone: {
    url: getStaticAssetPath('audios/v2/randomizing-done.mp3'),
    volume: 0.3,
    desc: 'Team randomizer is done',
  },
  cardWhooshAway: {
    url: getStaticAssetPath('audios/v2/card-whoosh-away.mp3'),
    volume: 1,
    desc: 'Card whoosh away',
  },
  yourTurnAlter: {
    url: getStaticAssetPath('audios/v2/your-turn-alter.mp3'),
    volume: 1,
    desc: 'Your turn alter',
  },
  instructionStart: {
    url: getStaticAssetPath('audios/v2/instruction-start.mp3'),
    volume: 1,
    desc: 'Start instruction block',
  },
  instructionReady: {
    url: getStaticAssetPath('audios/v2/instruction-ready.mp3'),
    volume: 1,
    desc: 'Click ready button in instruction block',
  },
  instructionHoverReadyButton: {
    url: getStaticAssetPath('audios/v2/instruction-hover-button.mp3'),
    volume: 1,
    desc: 'Hover on ready button in instruction block',
  },
  overRoastedTrashCup: {
    url: getStaticAssetPath('audios/v2/over-roasted-trash-cup.mp3'),
    volume: 1,
    desc: 'Trash cup',
  },
  overRoastedAddIngredient: {
    url: getStaticAssetPath('audios/v2/over-roasted-add-ingredient.wav'),
    volume: 1,
    desc: 'Add ingredient',
  },
  overRoastedMakeCoffee: {
    url: getStaticAssetPath('audios/v2/over-roasted-make-coffee.mp3'),
    volume: 1,
    desc: 'Make Coffee',
  },
  overRoastedServeCoffee: {
    url: getStaticAssetPath('audios/v2/over-roasted-serve-coffee.mp3'),
    volume: 1,
    desc: 'Serve Coffee',
  },
  overRoastedSelectCup: {
    url: getStaticAssetPath('audios/v2/over-roasted-select-cup.mp3'),
    volume: 1,
    desc: 'Select Cup',
  },
  overRoastedOpenOrders: {
    url: getStaticAssetPath('audios/v2/over-roasted-open-orders.mp3'),
    volume: 1,
    desc: 'Open orders',
  },
  overRoastedCloseOrders: {
    url: getStaticAssetPath('audios/v2/over-roasted-close-orders.mp3'),
    volume: 1,
    desc: 'Close orders',
  },
  spotlightConfetti: {
    url: getStaticAssetPath('audios/v2/spotlight-confetti.mp3'),
    volume: 1,
    desc: 'confetti',
  },
  spotlightBuzzer: {
    url: getStaticAssetPath('audios/v2/spotlight-buzzer.mp3'),
    volume: 1,
    desc: 'buzzer',
  },
  gameEnd: {
    url: getStaticAssetPath('audios/v2/game-end.mp3'),
    volume: 0.3,
    desc: 'Game Ended',
  },
  townhallTeamSwitchBackToCrowd: {
    url: getStaticAssetPath('audios/v2/townhall-team-switch-back-to-crowd.mp3'),
    volume: 0.3,
    desc: 'Townhall Team Swtich Back To Crowd',
  },
  drawingApplause: {
    url: getStaticAssetPath('audios/v2/drawing-applause.mp3'),
    volume: 1,
    desc: 'Applause',
  },
  drawingCashResgister: {
    url: getStaticAssetPath('audios/v2/drawing-cash-resgister.mp3'),
    volume: 1,
    desc: 'Cash Register',
  },
  drawingFailPick: {
    url: getStaticAssetPath('audios/v2/drawing-fail.mp3'),
    volume: 1,
    desc: 'Fail Pick',
  },
  drawingOhYeah: {
    url: getStaticAssetPath('audios/v2/drawing-oh-yeah.mp3'),
    volume: 1,
    desc: 'Oh Yeah',
  },
  drawingPageFlip: {
    url: getStaticAssetPath('audios/v2/drawing-page-flipping.mp3'),
    volume: 1,
    desc: 'Page Flip',
  },
  drawingPencil: {
    url: getStaticAssetPath('audios/v2/drawing-pencil.mp3'),
    volume: 1,
    desc: 'Pencil Drawing (Transition)',
  },
  drawingWrongPick: {
    url: getStaticAssetPath('audios/v2/drawing-wrong.mp3'),
    volume: 1,
    desc: 'Wrong Pick',
  },
  guessWhoDrumRoll: {
    url: getStaticAssetPath('audios/v2/guess-who-drum-roll.mp3'),
    volume: 0.7,
    desc: 'Drum Roll',
  },
  guessWhoDrumHit: {
    url: getStaticAssetPath('audios/v2/guess-who-drum-hit.mp3'),
    volume: 0.7,
    desc: 'Drum Hit',
  },
  guessWhoTextAppear: {
    url: getStaticAssetPath('audios/v2/guess-who-text-appear.mp3'),
    volume: 1,
    desc: 'Text Appear',
  },
  icebreakerCardShow: {
    url: getStaticAssetPath('audios/v2/icebreaker-card-show.mp3'),
    volume: 1,
    desc: 'Card Show',
  },
  icebreakerCardReveal: {
    url: getStaticAssetPath('audios/v2/icebreaker-card-reveal.mp3'),
    volume: 1,
    desc: 'Card Reveal',
  },
  scoreboardReorder: {
    url: getStaticAssetPath('audios/v2/scoreboard-reorder.mp3'),
    volume: 1,
    desc: 'Scoreboard Row Reorder',
    delayMs: 500,
  },
  scoreboardScoreCounting: {
    url: getStaticAssetPath('audios/v2/scoreboard-score-counting.mp3'),
    volume: 1,
    desc: 'Scoreboard Score Counting',
  },
  scoreboardScoreDeltaAppear: {
    url: getStaticAssetPath('audios/v2/scoreboard-score-delta-appear.mp3'),
    volume: 1,
    desc: 'Scoreboard Score Delta Appear',
  },
  roundRobinRaceCountdown: {
    url: getStaticAssetPath('audios/v2/round-robin-race-countdown.mp3'),
    volume: 1,
    desc: 'Countdown for Round Robin Race Mode',
  },
  roundRobinRaceGo: {
    url: getStaticAssetPath('audios/v2/round-robin-race-go.mp3'),
    volume: 1,
    desc: 'Go for Round Robin Race Mode',
  },
  jeopardyTimesUp: {
    url: getStaticAssetPath('audios/v2/jeopardy-times-up.mp3'),
    volume: 0.2,
    desc: 'Jeopardy Times Up',
  },
  jeopardyBoardFill: {
    url: getStaticAssetPath('audios/v2/jeopardy-board-fill.mp3'),
    volume: 1,
    desc: 'Jeopardy Board Fill',
  },
  jeopardyDing: {
    url: getStaticAssetPath('audios/v2/jeopardy-ding.mp3'),
    volume: 0.5,
    desc: 'Jeopardy Ding',
  },
  jeopardyBoardClear: {
    url: getStaticAssetPath('audios/v2/jeopardy-board-clear.mp3'),
    volume: 0.5,
    desc: 'Jeopardy Board Clear',
  },
  headToHeadDrumRoll: {
    url: getStaticAssetPath('audios/v2/head-to-head-drum-roll.mp3'),
    volume: 0.1,
    desc: 'Head to Head Drum Roll',
    loop: true,
  },
  headToHeadVote: {
    url: getStaticAssetPath('audios/v2/head-to-head-vote.mp3'),
    volume: 1,
    desc: 'Head to Head Vote',
  },
  headToHeadWin: {
    url: getStaticAssetPath('audios/v2/head-to-head-win.mp3'),
    volume: 1,
    desc: 'Head to Head Win',
  },
  headToHeadTurn: {
    url: getStaticAssetPath('audios/v2/your-turn-alter.mp3'),
    volume: 0.1,
    desc: 'Head to Head Turn',
  },
  blockStingerEnter: {
    url: getStaticAssetPath('audios/v2/block-stinger-enter.mp3'),
    volume: 1,
    desc: 'Block Stinger Enter',
  },
} as const;

export type SoundEffectMap = {
  [Property in keyof typeof SFX_MAP]: SoundEffect;
};

export type SoundEffectKeys = keyof SoundEffectMap;

export function getSoundEffectMap(): SoundEffectMap {
  return SFX_MAP;
}
