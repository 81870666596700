import { type Logger } from '@lp-lib/logger-base';

import { assertExhaustive } from '../../../utils/common';
import { type IVideoMixerTrack } from '../IVideoMixerTrack';
import { type VideoMixerEmitter } from '../types';
import { fixed4 } from '../utils';

export function audioElementTickHandler(
  prevTime: number,
  nextTime: number,
  track: IVideoMixerTrack,
  playing: boolean,
  log: Logger,
  emitter: VideoMixerEmitter
): void {
  const [startMs, endMs] = track.computeActiveTimelineTimeMs();

  const source = track.getAudioSource();
  if (playing && source) {
    const audioEv = track.nextAudioEvents(prevTime, nextTime);
    for (const ev of audioEv) {
      switch (ev.param) {
        case 'gain': {
          log.info(`track ${track.id} scheduling audio transition`, {
            playhead: fixed4(prevTime),
            startMs: fixed4(startMs),
            endMs: fixed4(endMs),
            param: ev.param,
            initialValue: ev.initialValue,
            goalValue: ev.goalValue,
            curve: ev.curve,
          });

          emitter.emit('track-audio-transition-start', track.id, prevTime);

          // We cannot do transitions since these are plain audio elements. Skip
          // ahead to the final value.
          source.volume = ev.goalValue;

          // TODO: it's possible to drive a transition manually using
          // setTimeout, but that requires 1) keeping more state than I am
          // willing to keep right now and 2) synchronizing the transition with
          // the play/pause state of the overall mixer.
          break;
        }

        default:
          assertExhaustive(ev.param);
      }
    }
  }
}
