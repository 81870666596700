import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from './audio-context';

// Play a local sound effect through the echo cancellation aparatus.
export function playLocalSoundEffect(
  buffer: AudioBuffer,
  gainValue = 1,
  delayMs = 0,
  loop = false,
  onEnded: () => void = () => void 0
): () => void {
  const ctx = getAudioContext();

  const absn = ctx.createBufferSource();
  const gain = ctx.createGain();
  gain.gain.value = gainValue;
  absn.connect(gain);
  gain.connect(getEchoCancelledAudioDestination());
  absn.buffer = buffer;
  absn.loop = loop;

  let playing = true;

  const stop = () => {
    if (playing) absn.stop();
    absn.buffer = null;
    absn.disconnect();
    gain.disconnect();
    onEnded();
  };

  absn.onended = () => {
    playing = false;
    stop();
  };

  const start = ctx.currentTime + delayMs / 1000;
  absn.start(start);

  return stop;
}

export function playLocalNonEchoCanceledSoundEffect(
  buffer: AudioBuffer,
  gainValue = 1,
  delayMs = 0,
  loop = false
): () => void {
  const ctx = getAudioContext();

  const absn = ctx.createBufferSource();
  const gain = ctx.createGain();
  gain.gain.value = gainValue;
  absn.connect(gain);
  gain.connect(ctx.destination);
  absn.buffer = buffer;
  absn.loop = loop;

  let playing = true;

  const stop = () => {
    if (playing) absn.stop();
    absn.buffer = null;
    absn.disconnect();
    gain.disconnect();
  };

  absn.onended = () => {
    playing = false;
    stop();
  };

  const start = ctx.currentTime + delayMs / 1000;
  absn.start(start);

  return stop;
}
