import { type CurveResult, type TwoTrackCurve } from './types';

function energyPreserving(x: number): CurveResult {
  // https://signalsmith-audio.co.uk/writing/2021/cheap-energy-crossfade/
  const k = -0.70912;
  const x2 = 1 - x;
  const a = x * x2;
  const b = a * (1 + k * a);
  const c = b + x;
  const d = b + x2;
  return [c * c, d * d];
}

export const EnergyPreservingCrossfade: TwoTrackCurve = {
  interp: energyPreserving,
  Complete: (vIn, vOut) => vIn >= 0.9999 && vOut <= 0.0001,
} as const;

export const SCurveCrossFade: TwoTrackCurve = {
  interp: (x: number) => {
    const phase = Math.PI / 2;
    const v = Math.sin(Math.PI * x - phase) / 2 + 0.5;
    return [v, 1 - v];
  },
  Complete: (vIn, vOut) => vIn >= 0.9999 && vOut <= 0.0001,
};
