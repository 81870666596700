import { proxy } from 'comlink';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { Emitter } from '../../utils/emitter';
import { MonotonicallyIncrementingId } from '../../utils/MonotonicallyIncrementingId';
import { createWorkerPoweredLoop } from '../Loop/createWorkerPoweredLoop';
import { createLoop } from '../Loop/loop';
import { type IVideoMixerTrack } from './IVideoMixerTrack';
import {
  type TrackId,
  type TrackInitConfig,
  type VideoMixerEmitter,
  type VideoMixerEvents,
} from './types';
import { fixed4, reportTrackDesyncs } from './utils';
import { VideoMixerAudioTrack } from './VideoMixerAudioTrack';
import { VideoMixerCanvasTrack } from './VideoMixerCanvasTrack';
import { VideoMixerImageTrack } from './VideoMixerImageTrack';
import {
  VideoMixerMarker,
  type VideoMixerMarkerConfig,
} from './VideoMixerMarker';
import { audioElementTickHandler } from './VideoMixerTickHandlers/AudioElementTickHandler';
import { markerReachedTickHandler } from './VideoMixerTickHandlers/MarkerReachedTickHandler';
import { playheadWithinTrackTickHandler } from './VideoMixerTickHandlers/PlayheadWithinTrackTickHandler';
import { trackStartTickHandler } from './VideoMixerTickHandlers/TrackPlayTickHandler';
import { trackSafeToRemoveTickHandler } from './VideoMixerTickHandlers/TrackSafeToRemoveTickHandler';
import { trackStopTickHandler } from './VideoMixerTickHandlers/TrackStopTickHandler';
import { VideoMixerTrack } from './VideoMixerTrack';

export const log = logger.scoped('media-element-tracker');

type StatsObserver = {
  beforeDraw: () => void;
  afterDraw: () => void;
  beforeTick: () => void;
  afterTick: () => void;
};

type MediaElementTrackerInitConfig = {
  tickFps?: number;
  autoRemoveTracksAfterSafeMs?: number | typeof Infinity;
  stats?: StatsObserver;
};

/**
 * The MediaElementTracker is effectively a representation of a list of tracks in a
 * digital video or audio editor: each "track" contains a source and metadata
 * about when to play and what effects (and how, as time progresses) to add to a
 * track.
 */
export class MediaElementTracker {
  protected emitter: VideoMixerEmitter = new Emitter<VideoMixerEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  private trackIdGen = new MonotonicallyIncrementingId<TrackId>('vm-track');

  private tracks: IVideoMixerTrack[] = [];

  private state: 'playing' | 'paused' = 'paused';
  private playhead = 0;
  private destroyLoop: () => void;

  private autoRemoveTracksAfterSafeMs: number | typeof Infinity;
  private markers: Map<VideoMixerMarker['id'], VideoMixerMarker> = new Map();
  private statsObserver: StatsObserver | null;

  constructor(
    config: MediaElementTrackerInitConfig,

    loopUseWorker = getFeatureQueryParam(
      'game-on-demand-video-mixer-loop-use-worker'
    ),
    loopMethod = getFeatureQueryParamArray(
      'game-on-demand-video-mixer-loop-method'
    ),
    createLoopFn = createLoop
  ) {
    const { tickFps, autoRemoveTracksAfterSafeMs } = {
      tickFps: 24,
      autoRemoveTracksAfterSafeMs: 5000,
      ...config,
    };

    this.autoRemoveTracksAfterSafeMs = autoRemoveTracksAfterSafeMs;

    this.statsObserver = config.stats ?? null;

    const MS_IN_SEC = 1000;
    const updateTime = MS_IN_SEC / tickFps;
    const drawTime = updateTime;

    if (loopUseWorker) {
      const loopWorker = createWorkerPoweredLoop();
      loopWorker.startAccumulated(
        loopMethod,
        {
          updateTime,
          drawTime,
          panicAt: Infinity, // never panic
        },
        proxy((dt) => this.tick(dt)),
        proxy((_interp, _dt) => void 0)
      );
      this.destroyLoop = () => {
        loopWorker.stop();
        loopWorker.terminate();
      };
    } else {
      this.destroyLoop = createLoopFn({
        kind: loopMethod,
        updateTime,
        drawTime,
        panicAt: Infinity, // never panic
        update: (dt) => this.tick(dt),
        draw: (_interp, _dt) => void 0,
      });
    }

    if (
      this.autoRemoveTracksAfterSafeMs > 0 &&
      this.autoRemoveTracksAfterSafeMs < Infinity
    ) {
      this.on('track-safe-to-remove', (trackId, playheadMs) => {
        log.info(`auto removing ${trackId} after safeMs`, {
          playheadMs: fixed4(playheadMs),
        });
        this.removeTrack(trackId);
      });
    }

    reportTrackDesyncs(this, () => this.tracks, log);

    log.info(`created ${tickFps}fps tick`);
  }

  pause(): void {
    if (!this.playing) return;
    this.state = 'paused';
    log.info('pausing', { playhead: this.playhead });
    this.emitter.emit('playstate', this.playing, this.playhead);
  }

  play(): void {
    if (this.playing) return;
    this.state = 'playing';
    log.info('playing', { playhead: this.playhead });
    this.emitter.emit('playstate', this.playing, this.playhead);
  }

  /**
   * Seek the player instantaneously. Tracks will behave as you expect. Be
   * prepared for events such as track-start/track-end to fire again if track
   * boundaries are crossed again! Note: There are no bounds restrictions since
   * the playhead will advance from wherever it is told to start, including a
   * negative value.
   */
  seek(playheadMs: number): void {
    log.info(`seeking to ${fixed4(playheadMs)}`, {
      playhead: fixed4(this.playhead),
      playing: this.playing,
    });
    this.playhead = playheadMs;
    this.emitter.emit('seek', this.playing, this.playhead);
  }

  get playing(): boolean {
    return this.state === 'playing';
  }

  get playheadMs(): number {
    return this.playhead;
  }

  /**
   * Push (add) a video as a new track with the given config for
   * scheduled/synchronized playback. Once given to the VideoMixer, it should be
   * assumed that all playback control and ownership over the video is
   * rescinded.
   *
   * Note: durationMs does not need to be the same as video.duration! A block
   * recording can be longer or shorter than the underlying video...
   */
  pushTrack(
    video:
      | HTMLVideoElement
      | HTMLImageElement
      | HTMLAudioElement
      | HTMLCanvasElement,
    trackDesc: TrackInitConfig
  ): TrackId {
    if (!trackDesc.durationMs)
      throw new Error('durationMs cannot be zero or otherwise ambiguous.');

    let t;

    if (video instanceof HTMLVideoElement) {
      t = new VideoMixerTrack(this.trackIdGen.next(), video, trackDesc);
    } else if (video instanceof HTMLAudioElement) {
      t = new VideoMixerAudioTrack(this.trackIdGen.next(), video, trackDesc);
    } else if (video instanceof HTMLCanvasElement) {
      t = new VideoMixerCanvasTrack(this.trackIdGen.next(), video, trackDesc);
    } else {
      t = new VideoMixerImageTrack(this.trackIdGen.next(), video, trackDesc);
    }

    return this.addTrack(t);
  }

  private addTrack(t: IVideoMixerTrack) {
    // Always keep tracks sorted according to readonly zLayer. Higher == drawn
    // later, "back to front".
    this.tracks.push(t);
    this.tracks.sort((a, b) => a.config.zLayer - b.config.zLayer);

    log.info(`pushed ${t.sourceDescription()}`, {
      config: {
        timelineTimeStartMs: fixed4(t.config.timelineTimeStartMs),
        durationMs: fixed4(t.config.durationMs),
        loop: t.config.loop,
      },
      playhead: fixed4(this.playhead),
      playing: this.playing,
    });

    return t.id;
  }

  async removeTrack(trackId: TrackId | null): Promise<void> {
    if (trackId === null) return;
    for (const [idx, t] of this.tracks.entries()) {
      if (t.id === trackId) {
        log.info(`removing ${t.sourceDescription()} at index ${idx}`, {
          playhead: fixed4(this.playhead),
        });
        this.emitter.emit(
          'track-remove',
          t.id,
          this.playhead,
          t.getAudioSource()
        );
        this.tracks.splice(idx, 1);
        await t.destroy();
        break;
      }
    }
  }

  async removeAllTracks(): Promise<void> {
    log.info('removing all tracks');

    for (const t of this.tracks) {
      await this.removeTrack(t.id);
    }

    log.info('removed all tracks');
  }

  patchTrack(
    trackId: TrackId | null,
    patch: Partial<TrackInitConfig>
  ): boolean {
    if (!trackId) return false;
    const track = this.tracks.find((t) => t.id === trackId);
    if (!track) return false;
    return track.acceptPatch(patch);
  }

  /**
   * Returns the start and end timelineTimes the track will be "active", aka
   * monitored for playback syncing, drawn, events scheduled, etc.
   */
  getTrackActiveTimelineTimeMs(
    query: TrackId | null
  ): readonly [number, number] | null {
    if (!query) return null;
    const track = this.tracks.find((t) => t.id === query);
    return track?.computeActiveTimelineTimeMs() ?? null;
  }

  /**
   * Return the track-relative elapsed time from the start of the track to the
   * playhead's current position. 0 can mean the track has not started to play
   * yet, while a value equal to the duration implies the track is finished
   * playing for now.
   */
  getTrackElapsedTimeMs(query: TrackId | null): number | null {
    const desc = this.getTrackActiveTimelineTimeMs(query);
    if (!desc) return null;
    const [startMs, endMs] = desc;
    const elapsed = this.playhead - startMs;
    return Math.min(Math.max(0, elapsed), endMs);
  }

  pushMarker(id: VideoMixerMarker['id'], config: VideoMixerMarkerConfig): void {
    const marker = new VideoMixerMarker(id, config);
    this.markers.set(marker.id, marker);
  }

  removeMarker(id: VideoMixerMarker['id']): void {
    this.markers.delete(id);
  }

  debugMarkers(): VideoMixerMarker[] {
    return Array.from(this.markers)
      .map(([, m]) => m)
      .sort(
        (a, b) => a.config.timelineTimeStartMs - b.config.timelineTimeStartMs
      );
  }

  private tick(elapsed: number) {
    this.statsObserver?.beforeTick();
    const prevTime = this.playhead;
    const nextTime = this.playhead + elapsed;

    // Ensure stable iteration outside of possible mutations
    const markersToProcess = Array.from(this.markers);
    for (const [, marker] of markersToProcess) {
      markerReachedTickHandler(
        prevTime,
        nextTime,
        marker,
        this.playing,
        log,
        this.emitter
      );
    }

    // Ensure we have stable iteration between event emissions, which could
    // mutate the list of tracks.
    const tracksToProcess = this.tracks.slice();

    for (const track of tracksToProcess) {
      audioElementTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter
      );

      trackStartTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter
      );

      playheadWithinTrackTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter
      );

      trackStopTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter
      );

      trackSafeToRemoveTickHandler(
        prevTime,
        nextTime,
        track,
        this.playing,
        log,
        this.emitter,
        this.autoRemoveTracksAfterSafeMs
      );
    }

    if (this.playing) {
      this.playhead = nextTime;
    }
    this.statsObserver?.afterTick();
  }

  async destroy(): Promise<void> {
    log.info('destroying');
    this.emitter.emit('before-destroy');
    this.destroyLoop();
    await Promise.all(this.tracks.map((t) => this.removeTrack(t.id)));
    this.tracks.length = 0;
    this.markers.clear();
    this.emitter.clear();
    log.info('destroyed');
  }
}
