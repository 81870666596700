import {
  AggregatedAnswers,
  QuestionBlockAnswerGrade,
  QuestionBlockDetailScore,
  TeamDataList,
} from './session';

export function aggregateAnswers(
  allTeamAnswers: TeamDataList<QuestionBlockDetailScore> | null,
  onlyGraded?: boolean
): AggregatedAnswers {
  return Object.entries(allTeamAnswers || {})
    .sort(
      ([, a], [, b]) =>
        (typeof a.submittedAt === 'number' ? a.submittedAt : 0) -
        (typeof b.submittedAt === 'number' ? b.submittedAt : 0)
    )
    .reduce((acc, curr) => {
      const teamId = curr[0];
      const val = curr[1];

      if (onlyGraded && val.grade === QuestionBlockAnswerGrade.NONE) {
        return acc;
      }

      // Default to aggregate only none-graded answers
      if (!onlyGraded && val.grade !== QuestionBlockAnswerGrade.NONE) {
        return acc;
      }

      if (val.answer && val.submitterUid) {
        const answer = val.answer.trim().toLowerCase();
        if (!acc[answer]) {
          acc[answer] = {
            submitters: [],
            grade: val.grade || QuestionBlockAnswerGrade.NONE,
            model: val.model,
            submittedAt: val.submittedAt || 0,
          };
        }
        acc[answer]?.submitters.push({
          submitterUid: val.submitterUid,
          teamId,
        });
      }

      return acc;
    }, {} as AggregatedAnswers);
}

/**
 * NOTICE: this is not a CSV parser and should never be extended to become one.
 * If you need a CSV parser (e.g. quoted values, headers, etc) then use a real
 * CSV parser.
 *
 * We actually have a somewhat unique pattern of stuffing multiple values into a
 * text field by allowing values to be "split" on commas. And sometimes,
 * multiple string fields are then JSON-encoded as an array to be stored in the
 * database or otherwise transferred. But then we needed a way to escape some
 * commas, and thus this class is born. By NOT using a CSV parser, the code is
 * actually remaining interoperable with user-authored data: users typing in
 * values and commas into spreadsheets or forms.
 */
export class Delimited {
  private results: string[] = [];
  private escaped = false;
  private token = '';
  private curr = '';

  constructor(private delimiter = ',', private escaper = '\\') {}

  parse(text: string): string[] {
    for (let i = 0; i < text.length; i++) {
      this.curr = text[i] ?? '';

      if (this.curr === this.escaper) {
        if (this.escaped) {
          this.consume();
        } else {
          this.escaped = true;
          continue;
        }
      } else if (this.curr === this.delimiter) {
        if (this.escaped) {
          this.consume();
        } else {
          this.emit();
          continue;
        }
      } else {
        this.consume();
      }
    }

    // Don't forget the last token!
    this.emit();

    return this.reset();
  }

  private consume() {
    this.escaped = false;
    this.token += this.curr;
  }

  private emit() {
    if (this.token.length) this.results.push(this.token);
    this.escaped = false;
    this.token = '';
  }

  private reset() {
    this.curr = '';
    this.escaped = false;
    this.token = '';
    const results = this.results;
    this.results = [];
    return results;
  }
}
