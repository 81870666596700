/**
 * A infinitely buffering, clearable, channel. Any pending takers are
 * immediately notified.
 */
export class Chan<T> {
  private items: T[] = [];
  private takers: ((item: T | null) => void)[] = [];
  private closed = false;

  private options: {
    bufferStrategy?: 'infinite' | 'drop';
    multicast?: boolean;
  } = {};

  constructor(options?: Chan<T>['options']) {
    this.options = {
      bufferStrategy: 'infinite',
      multicast: true,
      ...options,
    };
  }

  put(item: T): void {
    if (this.closed) return;
    let taken = false;

    if (this.options?.multicast) {
      for (const taker of this.takers) {
        taker(item);
        taken = true;
      }

      this.takers.length = 0;
    } else {
      const taker = this.takers.shift();
      if (taker) {
        taker(item);
        taken = true;
      }
    }

    if (!taken && this.options?.bufferStrategy === 'infinite') {
      this.items.push(item);
    }
  }

  peek(): T | 0 | null {
    if (this.closed) return 0;
    return this.items.length ? this.items[0] : null;
  }

  async take(): Promise<T | null> {
    if (this.closed) return null;
    const item = this.items.shift();
    if (item) return item;
    else
      return new Promise<T | null>((resolve) => {
        this.takers.push(resolve);
      });
  }

  clear(itemsToRemain = 0): void {
    // Allow the user to clear most but not all
    if (this.items.length >= itemsToRemain) {
      this.items.length = itemsToRemain;
    } else {
      this.items.length = 0;
    }

    // Clear any pending takers.
    for (const taker of this.takers) {
      taker(null);
    }

    this.takers.length = 0;
  }

  destroy(): void {
    this.clear();
    this.closed = true;
  }
}

export class StoredValueChan<T> extends Chan<T> {
  private storedValue: T | null = null;

  put(item: T): void {
    this.storedValue = item;
    super.put(item);
  }

  async take() {
    return this.storedValue ?? super.take();
  }
}
