const chromakeyVertexShaderRaw = `
attribute vec2 c; void main(void) { gl_Position=vec4(c, 0.0, 1.0); }
`;

export type IncompleteWGL = {
  gl: WebGLRenderingContext;
  prog: WebGLProgram;
};
export function createIncompleteWGL(
  cvs: HTMLCanvasElement,
  fragmentShaderSourceRaw: string
): IncompleteWGL {
  const gl = cvs.getContext('webgl', {
    alpha: true,
    premultipliedAlpha: false,
  });

  if (!gl) throw new Error('Failed to initialize webgl context');

  const vs = gl.createShader(gl.VERTEX_SHADER);
  if (!vs) throw new Error('Could not create VERTEX_SHADER');
  gl.shaderSource(vs, chromakeyVertexShaderRaw);
  gl.compileShader(vs);

  const fs = gl.createShader(gl.FRAGMENT_SHADER);
  if (!fs) throw new Error('Could not create FRAGMENT_SHADER');
  gl.shaderSource(fs, fragmentShaderSourceRaw);
  gl.compileShader(fs);
  if (!gl.getShaderParameter(fs, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(fs));
  }

  const prog = gl.createProgram();
  if (!prog) throw new Error('Could not create WebGL Program');

  gl.attachShader(prog, vs);
  gl.attachShader(prog, fs);
  gl.linkProgram(prog);
  gl.useProgram(prog);

  const vb = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vb);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([-1, 1, -1, -1, 1, -1, 1, 1]),
    gl.STATIC_DRAW
  );

  const coordLoc = gl.getAttribLocation(prog, 'c');
  gl.vertexAttribPointer(coordLoc, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(coordLoc);

  gl.activeTexture(gl.TEXTURE0);
  const tex = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, tex);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

  return {
    gl,
    prog,
  };
}
