import { type Logger } from '@lp-lib/logger-base';

import { getLogger } from '../logger/logger';

class PlayWithCatchError extends Error {
  name = 'PlayWithCatchError';
}

export async function playWithCatch(
  el: Nullable<HTMLMediaElement>,
  log: Logger | null = getLogger().scoped('play-with-catch')
) {
  try {
    return await el?.play();
  } catch (cause) {
    const error =
      cause instanceof Error
        ? cause
        : new PlayWithCatchError('failed', { cause });
    if (log) log.error(error.message, error);
    else console.error(error.message, error);
  }
}
